<template>
    <div class="item">
        <div class="my-list" @click="show = !show">
            <span class="li-left">
            <span class="word">春季</span>
            </span>
            <i  class="list-arrow" :class="show && 'down'"/>
        </div>
        <div class="second" v-show="show">
            <div class="my-list">
                <span class="li-left">
                <span class="word">春季周期问题</span>
                </span>
                <i />
            </div>
            <div class="my-list">
                <span class="li-left">
                <span class="word">春季周期问题</span>
                </span>
                <i />
            </div>
            <div class="my-list">
                <span class="li-left">
                <span class="word">春季周期问题</span>
                </span>
                <i />
            </div>
        </div>
    </div>
</template>

<script>

export default {
  props: ['title'],
  components: {
    
  },
  data () {
      return {
        show: false,
      }
  },
  mounted() {
    // console.log(this.props);
  },
  methods: {
    
  }

}
</script>

<style lang="less" scoped>
    .my-list {
        text-decoration: none;
        height: 1.09rem;
        padding: 0 0.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d9d9d9;
        background-color: #f7f7f7;
        position: relative;
        color: #333333;
        .li-left {
            display: flex;
            align-items: center;
            img {
                width: 0.45rem;
                height: 0.45rem;
                display: inline-block;
                margin-right: 0.3rem;
            }
            .word {
                font-size: 0.32rem;
            }
        }
        .list-arrow {
            // position: absolute;
            // right: 0.3rem;
            // top: 50%;
            // transform: translateY(-50%);
            &.down {
                transform: rotate(180deg);
            }
            width: 0.35rem;
            height: 0.35rem;
            background-image: url('../image/second/top.png');
            background-size: 100% 100%;
            display: block;
        }
    }
    .second {
        .my-list {
            background-color: #ededed;
            .word {
                padding-left: 0.3rem;
            }
        }
    }
</style>
