<template>
    <div class="class">
      <img class="up" src="../../../image/second/class.png" />
      <div class="upBtn" @click="showDialog = true">出题</div>
      <transition name="fold">
        <div class="dialog" v-show="showDialog">
          <div class="head">
            选择类目
            <i class="left" @click="showDialog = false"/>
          </div>

          <div class="cn">
            <div class="list">
              <p class="title">学科</p>
              <p class="cate">
                <span 
                  class="item"
                  :class="id1 == it.id && 'active'"
                  v-for="it in list1" 
                  :key="it.id"
                  @click="changeType(it.id, 1)"
                >
                  {{it.subjectDictName}}
                </span>
              </p>
            </div>

            <div class="list">
              <p class="title">一级分类</p>
              <p class="cate">
                <span 
                  class="item"
                  :class="id2 == it.id && 'active'"
                  v-for="it in list2" 
                  :key="it.id"
                  @click="changeType(it.id, 2)"
                >
                  {{it.subjectFirstDictName}}
                </span>
              </p>
            </div>

            <div class="list">
              <p class="title">二级分类</p>
              <p class="cate">
                <span 
                  class="item"
                  :class="id3 == it.id && 'active'"
                  v-for="it in list3" 
                  :key="it.id"
                  @click="changeType(it.id, 3)"
                >
                  {{it.subjectSecondDictName}}
                </span>
              </p>
            </div>
          </div>
          <div class="upBtn" @click="editTimu">
            出题
          </div>
        </div>
      </transition>

    </div>
</template>

<script>

export default {
  props: ['title'],
  components: {
    
  },
  data () {
    return {
      showDialog: false,
      list1: [],
      list2: [],
      list3: [],
      id1: '',
      id2: '',
      id3: '',
    }
  },
  mounted() {
    // console.log(this.props);
    this.getTi1();
  },
  methods: {
    changeType(id, curr) {
      if (curr == 1) {
        this.id1 = id;
        this.getTi2()
      }
      if (curr == 2) {
        this.id2 = id;
        this.getTi3()
      }
      if (curr == 3) {
        this.id3 = id;
      }
    },
    getTi1() {
      this.$http.get(`/api/subjectDict/list`, {
      })
      .then((res) => {
        this.list1 = res.data.data;
        this.id1 = this.list1[0].id;
        this.getTi2();
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
    getTi2() {
      this.$http.get(`/api/subjectFirstDict/list/${this.id1}`, {
      })
      .then((res) => {
        this.list2 = res.data.data;
        this.id2 = this.list2[0].id;
        this.getTi3();
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
    getTi3() {
      this.$http.get(`/api/subjectSecondDict/list/${this.id2}`, {
      })
      .then((res) => {
        this.list3 = res.data.data;
        this.id3 = this.list3[0].id;
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
    editTimu() {
      if (!this.id3) {
        alert('暂无题目信息!');
        return;
      }
      this.$http.get(`/api/subjectCate/list/${this.id3}`, {
      })
      .then((res) => {
        if (res.data.data.length === 0) {
          alert('暂无题目信息!');
          return;
        } else {
          this.$router.push(`/classList/${this.id3}`)
        }
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    }
  }

}
</script>

<style lang="less" scoped>
    .class {
      position: relative;
      min-height: 100vh;
      padding-bottom: 2rem;
      box-sizing: border-box;
      .up {
        width: 2rem;
        height: 2rem;
        margin: 0 auto;
        display: block;
        padding-top: 3rem;
      }
      .upBtn {
        width: 3.5rem;
        height: 1rem;
        line-height: 1rem;
        text-align: center;
        color: white;
        background-color: #79cdca;
        margin: 1rem auto 2rem;
        border-radius: 1rem;
        font-size: 0.35rem;
      }
      .dialog {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 10;
        min-height: 100%;
        padding-bottom: 1rem;
        .head {
          height: 1rem;
          border-bottom: 1px solid  #d9d9d9;
          position: relative;
          line-height: 1rem;
          text-align: center;
          font-size: 0.36rem;
          position: relative;
          .left {
            position: absolute;
            left: 0.3rem;
            top: 50%;
            transform: translateY(-50%);
            width: 0.5rem;
            height: 0.5rem;
            display: block;
            background-image: url('../../../image/second/left.png');
            background-size: 100% 100%;
          }
        }
        .cn {
          margin-top: 0.4rem;
          .list {
            padding: 0 0.3rem;
            // margin-top: 0.4rem;
            .title {
              color: #333333;
              font-size: 0.36rem;
              font-weight: bold;
            }
            .cate {
              margin-top: 0.3rem;
              .item {
                &.active {
                  background-color: #79cdca;
                  color: white;
                }
                padding: 0.15rem 0.45rem;
                background-color: #d9d9d9;
                font-size: 0.36rem;
                border-radius: 0.5rem;
                margin-right: 0.25rem;
                display: inline-block;
                margin-bottom: 0.3rem;
                color: #666666;
              }
            }
          }
        }
      }
    }
</style>
