<template>
    <div class="work">
        <vue-picker 
            class="picker"
            :data="pickData"
            @cancel="cancel"
            @confirm="confirm"
            :showToolbar="true"
            :maskClick="true"
            :visible.sync="pickerVisible"
        />

        <div class="head">
            <div class="nav">
                <p :class="buyInfo.sendType == 1 && 'active'" @click="buyInfo.sendType = 1">自取</p>
                <p :class="buyInfo.sendType == 2 && 'active'" @click="buyInfo.sendType = 2">邮寄</p>
            </div>
        </div>

        <div v-show="buyInfo.sendType == 1">
            <p class="title">自取地址</p>
            <div class="my-list tit">
                <input disabled v-model="selfAddress"/>
            </div>
        </div>

        <div v-show="buyInfo.sendType == 2">
            <p class="title">收件人(必填)</p>
            <div class="my-list tit">
                <input placeholder="请输入收件人" v-model="buyInfo.receiverName"/>
            </div>

            <p class="title">手机号(必填)</p>
            <div class="my-list tit">
                <input placeholder="请输入手机号" v-model="buyInfo.receiverMobile" maxlength="11"/>
            </div>

            <p class="title">省市(必选)</p>
            <div class="my-list tit" @click="pickOpen">
                <span class="li-left">
                    <span class="word">{{province}}</span>
                </span>
                <i class="list-arrow" />
            </div>

            <p class="title">详细地址(必选)</p>
            <div class="my-list">
                <textarea placeholder="请输入详细地址" v-model="buyInfo.address"/>
            </div>
        </div>

        <div class="button">
            <div class="cancel" @click="cancelBuy">取消</div>
            <div class="buy" @click="buyCourse">¥{{price}} 确认购买</div>
        </div>
        <Confirm :tip.sync="title" :callback="sureBuy" />

        <div class="">
            <PayCode :show.sync="show" :url="url"/>
        </div>
    </div>
</template>

<script>
import { getQuery } from '../../common/util';
import PayCode from '@/components/PayCode';
export default {
  components: {
    PayCode
  },
  data () {
      return {
        id: 0,
        show: false,
        info: {},
        type: 1,
        selfAddress: '浙江省江山市江东区91号',
        price: 0,
        title: '',
        url: '',
        buyInfo: {
            sendType: 1, // 1自取  2邮寄（必传）"
            receiverName: '', // /收件人姓名
            receiverMobile: '', // /收件人电话
            provinceCode: '', // 省编码
            address: '', //详细地址,
            payMethod: 3,
            isGroup: 0, // 0否 1是（必传）" 是否是团购：0否 1是（必传）"
            payType: 1, //购买类型：1课程 2：系列（必传）",
            courseId: '', //课程id（购买类型为课程的时候传递）",
            courseSeriesId: '', // 课程系列id（购买类型为系列的时候传递）",
        },
        provinceCodeList: [],
        province: '',
        pickerVisible: false,
        pickData: [],
      }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.type = getQuery('type');
    this.getDetail();
    this.getCode();
  },
  methods: {
    cancelBuy() {
        this.$router.go(-1);
    },
    sureBuy() {
        // this.show = true;
        let buyInfo = JSON.parse(JSON.stringify(this.buyInfo));
        this.$http.post(`/api/order/save`, buyInfo)
        .then((res) => {
            let wechatBody = res.data.data.wechatBody;
            this.url = JSON.parse(wechatBody).codeurl;
            this.show = true;
        }).catch((err) => {
            this.validErrorInfo = err.errorMsg;
        })
    },
    buyCourse() {
        if (this.buyInfo.sendType == 1) {
            this.title = '确定购买该课程？';
            return;
        }
        if (!this.buyInfo.receiverName) {
            alert('收件人姓名不能为空');
            return;
        }
        if (!this.buyInfo.receiverMobile) {
            alert('手机号不能为空');
            return;
        }
        if (this.buyInfo.receiverMobile.length !== 11) {
            alert('手机号格式不正确');
            return;
        }
        if (!this.buyInfo.provinceCode) {
            alert('省市不能为空');
            return;
        }
        if (!this.buyInfo.address) {
            alert('详细地址不能为空');
            return;
        }
        this.title = '确定购买该课程？';
    },
    getCode(id = 1) {
        this.$http.get(`/anonymous/sysCity/getCityListByParentCode//${id}`, {
        })
        .then((res) => {
            this.provinceCodeList = res.data.data;
            this.province = this.provinceCodeList[0].name;
            this.buyInfo.provinceCode = this.provinceCodeList[0].code;
        }).catch((err) => {
            this.validErrorInfo = err.errorMsg;
        })
    },
    getDetail() {
      this.$http.get(`/api/course/getCourseInfo/${this.id}`, {
      })
      .then((res) => {
        this.info = res.data.data;
        if (this.info.isPayment == 1) {
          if (this.info.purchasedCourse == 1) {
            this.info.isPayment = 2
          }
        }
        // 表明已经购买 
        if (this.info.isPayment != 1) {
            this.$router.go(-1);
            return;
        }
        if (this.type == 1) {
            this.price = this.info.coursePrice;
            this.buyInfo.isGroup = 0;
            this.buyInfo.courseId = this.id;
        }
        if (this.type == 2) {
            this.price = this.info.courseSeriesPrice;
            this.buyInfo.isGroup = 0;
            this.buyInfo.payType = 2;
            this.buyInfo.courseId = this.id;
            this.buyInfo.courseSeriesId = this.info.courseSeriesId
        }
        if (this.type == 3) {
            this.price = this.info.courseSeriesGroupPrice;
            this.buyInfo.isGroup = 1;
            this.buyInfo.payType = 2;
            this.buyInfo.courseId = this.id;
            this.buyInfo.courseSeriesId = this.info.courseSeriesId
        }
      }).catch((err) => {
        this.validErrorInfo = err.errorMsg;
      })
    },
    pickOpen() {
        let tdata = [];
        let data = this.provinceCodeList;
        for (let i = 0; i< data.length; i ++) {
            tdata.push({
                label: data[i]?.name || data[i],
                value: data[i]?.code || data[i],
            })
        }
        this.pickData = [ tdata ];
        this.pickerVisible = true;
    },
    cancel () {
        console.log('cancel')
    },
    confirm (res) {
        this.buyInfo.provinceCode = res[0].value;
        this.province = res[0].label;
    }
  }

}
</script>

<style lang="less" scoped>
    .work {
        min-height: 100vh;
        background-color: #f4f6f6;
        .head {
            height: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background-color: #79cdca;
            .nav {
                background-color: #f4f4f4;
                display: flex;
                width: 3rem;
                color: #999;
                height: 0.7rem;
                line-height: 0.7rem;
                font-size: 0.3rem;
                border: 1px solid white;
                border-radius: 0.7rem;
                align-items: center;
                box-sizing: border-box;
                p {
                    height: 100%;
                    border-radius: 0.7rem;
                    text-align: center;
                    flex: 1;
                    &.active {
                        background-color: #8bcbc7;
                        color: white;
                    }
                }
            }
        }
        .title {
            height: 0.7rem;
            padding: 0 0.3rem;
            display: flex;
            align-items: center;
            font-size: 0.26rem;
        }
        .my-list {
            text-decoration: none;
            height: 2.5rem;
            padding: 0 0.3rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #d9d9d9;
            background-color: white;
            position: relative;
            color: #333333;
            font-size: 0.3rem;
            &.tit {
                height: 1rem;
            }
            input {
                height: 0.95rem;
                width: 92%;
                font-size: 0.3rem;
                background: none;
            }
            textarea {
                height: 1.98rem;
                width: 92%;
                font-size: 0.3rem;
            }
            .list-arrow {
                // position: absolute;
                // right: 0.3rem;
                // top: 50%;
                // transform: translateY(-50%);
                width: 0.39rem;
                height: 0.39rem;
                background-image: url('../../image/second/arrow1.png');
                background-size: 100% 100%;
                display: block;
            }
        }
        .imgList {
            background-color: white;
            padding: 0.3rem;
            box-sizing: border-box;
                .img-item {
                width: 1.8rem;
                height: 1.8rem;
                border-radius: 0.12rem;
                background-color: #f4f6f6;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 0.3rem;
                img {
                    display: block;
                    margin: 0 auto;
                    width: 0.7rem;
                    height: 0.7rem;
                }
            }
        }
        .button {
            font-size: 0.32rem;
            color: white;
            position: fixed;
            width: 100%;
            max-width: 7.5rem;
            transform: translateX(-50%);
            left: 50%;
            bottom: 0.3rem;
            text-align: center;
            .cancel {
                display: inline-block;
                background-color: #79cdca;
                height: 1rem;
                line-height: 1rem;
                border-radius: 1rem;
                width: 3rem;
                text-align: left;
                padding-left: 0.8rem;
                box-sizing: border-box;
            }
            .buy {
                display: inline-block;
                height: 1rem;
                width: 4rem;
                border-radius: 1rem;
                line-height: 1rem;
                text-align: center;
                background-image: linear-gradient(to right, #f0bf5a, #e8343a);
                margin-left: -1rem;
            }
        }
        
    }
</style>
