import Vue from 'vue'
import VueRouter from 'vue-router'
const Login = () => import('../views/Login.vue')
const Register = () => import('../views/Register.vue')
const Forget = () => import('../views/Forget.vue')
const Main = () => import('../views/Main.vue')
const Home = () => import('../views/pages/Home.vue')
const Answer = () => import('../views/pages/Answer.vue')
const Test = () => import('../views/pages/Test.vue')
const My = () => import('../views/pages/My.vue')

import PingTuan from '../views/Second/PingTuan.vue';
import ScoreShop from '../views/Second/ScoreShop.vue'
import Message from '../views/Second/Message.vue';
import Setting from '../views/Second/Setting.vue';
import PersonInfo from '../views/Second/PersonInfo.vue';
import ModName from '../views/Second/ModName.vue';
import ModAddress from '../views/Second/ModAddress.vue';
import TestDetail from '../views/Second/TestDetail.vue';

import About from '../views/Third/About.vue';
import ModPassword from '../views/Third/ModPassword.vue';
import AgreeMent from '../views/Third/AgreeMent.vue';
import ScoreRule from '../views/Third/ScoreRule.vue';
import ScoreHistory from '../views/Third/ScoreHistory.vue';
import AppdownLoad from '../views/Third/AppdownLoad.vue'

import AskAnswer from '../views/Task/AskAnswer.vue';
import AskDetail from '../views/Task/AskDetail.vue';
import AskBack from '../views/Task/AskBack.vue';
import ClassSelect from '../views/Task/ClassSelect/ClassSelect.vue';
import ClassList from '../views/Task/ClassSelect/ClassList.vue';
import ClassMake from '../views/Task/ClassSelect/ClassMake.vue';

const JionWork = () => import('../views/Task/JionWork.vue')
const OneHundred = () => import('../views/Task/OneHundred.vue')
const OneWrongList = () => import('../views/Task/OneWrongList.vue')
const OneHundredToday = () => import('../views/Task/OneHundredToday.vue')
const OneWrongDetail = () => import('../views/Task/OneWrongDetail.vue')
const ActivyRule = () => import('../views/Third/ActivyRule.vue')
const LookRank = () => import('../views/Third/LookRank.vue')

// import LookRank from '../views/Third/LookRank.vue'
// import ActivyRule from '../views/Third/ActivyRule.vue';
// import OneHundred from '../views/Task/OneHundred.vue';
// import OneWrongList from '../views/Task/OneWrongList.vue';
// import OneHundredToday from '../views/Task/OneHundredToday.vue';
// import OneWrongDetail from '../views/Task/OneWrongDetail.vue';
import UploadWork from '../views/Task/UploadWork.vue';
import WrongList from '../views/Task/WrongList.vue';

import WrongDetail from '../views/Task/WrongDetail.vue';
import AnswerDetail from '../views/Task/AnswerDetail.vue';

import CourseBuy from '../views/Course/CourseBuy.vue'
import CourseList from '../views/Course/CourseList.vue'
import CourseIndex from '../views/Course/CourseIndex.vue'
import CourseSend from '../views/Course/CourseSend.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/main/home'
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: '登陆'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      title: '注册明九章'
    }
  },
  {
    path: '/forget',
    name: 'Forget',
    component: Forget,
    meta: {
      title: '忘记密码'
    }
  },
  {
    path: '/courseBuy/:id',
    name: 'CourseBuy',
    component: CourseBuy,
    meta: {
      title: '教材详情'
    }
  },
  {
    path: '/courseList/:id',
    name: 'CourseList',
    component: CourseList,
    meta: {
      title: '课程列表'
    }
  },
  {
    path: '/courseIndex',
    name: 'CourseIndex',
    component: CourseIndex,
    meta: {
      title: '学习与购买'
    }
  },
  {
    path: '/courseSend/:id',
    name: 'CourseSend',
    component: CourseSend,
    meta: {
      title: '购买教材'
    }
  },
  {
    path: '/main',
    name: 'Main',
    component: Main,
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home,
        meta: {
          title: '首页'
        }
      },
      {
        path: 'answer',
        name: 'answer',
        component: Answer,
        meta: {
          title: '疑难'
        }
      },
      {
        path: 'test',
        name: 'test',
        component: Test,
        meta: {
          title: '测试成绩'
        }
      },
      {
        path: 'my',
        name: 'my',
        component: My,
        meta: {
          title: '我的'
        }
      },
    ]  
  },
  {
    path: '/pingTuan',
    name: 'PingTuan',
    component: PingTuan,
    meta: {
      title: '拼团'
    }
  },
  {
    path: '/scoreShop',
    name: 'ScoreShop',
    component: ScoreShop,
    meta: {
      title: '积分商城'
    }
  },
  {
    path: '/scoreRule',
    name: 'ScoreRule',
    component: ScoreRule,
    meta: {
      title: '积分规则'
    }
  },
  {
    path: '/ScoreHistory',
    name: 'ScoreHistory',
    component: ScoreHistory,
    meta: {
      title: '积分兑换记录'
    }
  },
  {
    path: '/message',
    name: 'Message',
    component: Message,
    meta: {
      title: '消息中心'
    }
  },
  {
    path: '/setting',
    name: 'Setting',
    component: Setting,
    meta: {
      title: '设置'
    }
  },
  {
    path: '/personInfo',
    name: 'PersonInfo',
    component: PersonInfo,
    meta: {
      title: '个人信息'
    }
  },
  
  {
    path: '/modName',
    name: 'ModName',
    component: ModName,
    meta: {
      title: '编辑昵称'
    }
  },
  {
    path: '/modAddress',
    name: 'ModAddress',
    component: ModAddress,
    meta: {
      title: '修改地址'
    }
  },
  {
    path: '/testDetail/:id',
    name: 'TestDetail',
    component: TestDetail,
    meta: {
      title: '修改地址'
    }
  },
  
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: '关于'
    }
  },
  {
    path: '/modPassword',
    name: 'ModPassword',
    component: ModPassword,
    meta: {
      title: '修改密码'
    }
  },
  {
    path: '/agreeMent',
    name: 'AgreeMent',
    component: AgreeMent,
    meta: {
      title: '用户协议'
    }
  },
  {
    path: '/activyRule',
    name: 'ActivyRule',
    component: ActivyRule,
    meta: {
      title: '活动规则'
    }
  },
  {
    path: '/lookRank',
    name: 'LookRank',
    component: LookRank,
    meta: {
      title: '排行榜'
    }
  },
  {
    path: '/appdownLoad',
    name: 'AppdownLoad',
    component: AppdownLoad,
    meta: {
      title: '下载'
    }
  },
  {
    path: '/askAnswer',
    name: 'AskAnswer',
    component: AskAnswer,
    meta: {
      title: '问答'
    }
  },
  {
    path: '/askDetail/:id',
    name: 'AskDetail',
    component: AskDetail,
    meta: {
      title: '问答'
    }
  },
  {
    path: '/askBack',
    name: 'AskBack',
    component: AskBack,
    meta: {
      title: '回复'
    }
  },
  {
    path: '/classSelect',
    name: 'ClassSelect',
    component: ClassSelect,
    meta: {
      title: '答题'
    }
  },
  {
    path: '/classList/:id',
    name: 'ClassList',
    component: ClassList,
    meta: {
      title: '选择类目'
    }
  },
  {
    path: '/classMake/:id',
    name: 'classMake',
    component: ClassMake,
    meta: {
      title: '答题'
    }
  },
  {
    path: '/jionWork',
    name: 'JionWork',
    component: JionWork,
    meta: {
      title: '合作加盟'
    }
  },
  {
    path: '/oneHundred',
    name: 'OneHundred',
    component: OneHundred,
    meta: {
      title: '每日一练'
    }
  },
  {
    path: '/oneWrongList',
    name: 'OneWrongList',
    component: OneWrongList,
    meta: {
      title: '每日错题'
    }
  },
  {
    path: '/oneHundredToday',
    name: 'OneHundredToday',
    component: OneHundredToday,
    meta: {
      title: '今日答题'
    }
  },
  {
    path: '/uploadWork',
    name: 'UploadWork',
    component: UploadWork,
    meta: {
      title: '上传作业'
    }
  },
  {
    path: '/wrongList',
    name: 'WrongList',
    component: WrongList,
    meta: {
      title: '错题集'
    }
  },
  
  {
    path: '/oneWrongDetail/:id',
    name: 'OneWrongDetail',
    component: OneWrongDetail,
    meta: {
      title: '详情'
    }
  },
  {
    path: '/wrongDetail',
    name: 'WrongDetail',
    component: WrongDetail,
    meta: {
      title: '详情'
    }
  },
  {
    path: '/answerDetail/:id',
    name: 'AnswerDetail',
    component: AnswerDetail,
    meta: {
      title: '作业详情'
    }
  },
  

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router

// 配置不需要登陆的页面
const NotLogin = [
  '/login',
  '/forget',
  '/register',
  '/oneHundred',
  '/jionWork',
  '/agreeMent',
  '/appdownLoad'
]

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta?.title || '明九章';
  }

  if (localStorage.getItem("ng-token") || NotLogin.includes(to.path)) { // 判断本地是否存在
    next()
  } else {
    // 未登录,跳转到登陆页面
    next({  
      path: '/login',
    })
  }
});
