<template>
    <div class="wrong">
      <Empty title="暂未收录错题" v-if="records.length == 0" />
      <div class="loadMore" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
        <div
          @click="toDetail(it)"
          v-for="it in records"
          :key="it.id"
          class="list"
          v-show="!it.del"
        >
          <div class="head">
            <img class="misImg" src="../../image/task/mistakes.png" />
            <p>{{`${it.subjectFirstDictName}-${it.subjectSecondDictName}-${it.subjectCateName}`}}</p>
            <i class="close" @click="delList(it, $event)"/>
          </div>
          <p class="time">收录于：{{it.gmtCreate}}</p>
          <p class="cn">
            {{it.title}}
          </p>
        </div>
      </div>
      <Tips :tip="msg" />
      <Confirm :tip.sync="title" :callback="sureList" />
    </div>
</template>

<script>
import Empty from '@/components/Empty';
export default {
  components: {
    Empty
  },
  data () {
      return {
        records: [],
        busy: false,
        current: 1,
        size: 10,
        title: '',
        msg: ''
      }
  },
  mounted() {
    // console.log(this.props);
  },
  methods: {
    delList(item, $event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.title = '确定删除该错题？';
      this.item = item;
    },
    toDetail(it) {
      sessionStorage.setItem('wrongInfo', JSON.stringify(it));
      setTimeout(() => {
        this.$router.push('/wrongDetail')
      })
    },
    sureList() {
      this.$http.put(`/api/answerLog/wrongCollectionRemove/${this.item.id}`, {
      })
      .then((res) => {
        this.title = '';
        this.msg = '删除成功';
        this.item.del = true;
        this.$forceUpdate();
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
    loadMore() {
      this.$http.post('/api/answerLog/wrongCollectionList', {
        size: this.size,
        current: this.current,
      })
      .then((res) => {
        let data = res.data.data;
        if (this.records.length > 0 && data.records.length === 0) {
          this.busy = true;
          return;
        };
        this.records = this.records.concat(data.records);
        this.current ++;
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
  }

}
</script>

<style lang="less" scoped>
    .wrong {
      min-height: 100vh;
      background-color: #f4f6f6;
      .loadMore {
        height: 100vh;
        overflow: scroll;
      }
      .list {
        padding: 0.3rem;
        box-sizing: border-box;
        background-color: white;
        margin-bottom: 0.25rem;
        .head {
          display: flex;
          position: relative;
          .close {
            position: absolute;
            right: 0.3rem;
            top: 50%;
            transform: translateY(-50%);
            background-image: url('../../image/task/close.png');
            background-size: 100% 100%;
            width: 0.32rem;
            height: 0.32rem;
          }
          .misImg {
            width: 0.45rem;
            height: 0.45rem;
          }
          p {
            font-size: 0.32rem;
            margin-left: 0.2rem;
            width: 5.5rem;
          }
        }
        .time {
          color: #999999;
          margin-top: 0.1rem;
          font-size: 0.26rem;
        }
        .cn {
          font-size: 0.32rem;
          margin-top: 0.15rem;
        }
      }
    }
</style>
