<template>
    <div class="wrong">
        <div class="list">
            <div class="head">
            <img class="misImg" src="../../image/task/mistakes.png" />
            <p>错题集</p>
            </div>
            <p class="time">收录于：{{info.gmtCreate}}</p>
            <p class="cn">
                {{info.title}}
            </p>
            <p class="wrongImg">
                <ImageShow :src="info.titleImage" class="img" />   
            </p>
            <p class="select" v-if="info.type == 1">
                <span 
                  v-for="(it, index) in info.answer"
                  :key="index"
                >
                  {{selectList[index]}}、{{it}}
                </span>
            </p>
            <p class="select" v-if="info.type == 2">
                <span 
                  v-for="(it, index) in info.answer"
                  :key="index"
                >
                  填空{{index + 1}}、{{it}}
                </span>
            </p>
        </div> 

        <div class="intro">
          <p>题目解析</p>
          <div class="intro-l">
                <p class="intro-a">
                  【答案】{{info.answer | tranAn}}
                </p>
                <p class="intro-a">
                  【解析】{{info.answerContent | tranCn}}
                </p>
                <div class="daCn" v-if="info.answerImage">
                  <ImageShow :src="info.answerImage" class="daImg" /> 
                </div>
          </div>
        </div>

    </div>
</template>

<script>
import ImageShow from '@/components/ImageShow';
export default {
  props: ['title'],
  components: {
    ImageShow
  },
  filters: {
    tranAn: function (value) {
      if (!value) return ''
      return value.join(',')
    },
    tranCn: function(value) {
      if (!value) return '略略'
      return value
    }
  },
  data () {
      return {
        id: '',
        select: -1,
        selectList: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
        info: {
            activityId: 1,
            answer: "",
            answerContent: "",
            answerImage: "",
            id: '',
            integral: '',       
            requestOption: [],
            title: "",
            titleImage: "",
            type: 1, // 1-选则题， 2-填空题
        },
        // 填空
        tianList: [],
      }
  },
  mounted() {
    // console.log(this.props);
    let info = sessionStorage.getItem('wrongInfo')
    if (info) {
      info = JSON.parse(info);
      info.requestOption = JSON.parse(info.requestOption);
      info.answer = JSON.parse(info.answer);
      this.info = info;
    }
  },
  methods: {
    
  }

}
</script>

<style lang="less" scoped>
    .wrong {
      min-height: 100vh;
      background-color: #f4f6f6;
      .list {
        padding: 0.3rem;
        box-sizing: border-box;
        background-color: white;
        margin-bottom: 0.25rem;
        .head {
          display: flex;
          position: relative;
          .misImg {
            width: 0.45rem;
            height: 0.45rem;
          }
          p {
            font-size: 0.32rem;
            margin-left: 0.2rem;
            width: 5.5rem;
          }
        }
        .time {
          color: #999999;
          margin-top: 0.1rem;
          font-size: 0.26rem;
        }
        .cn {
          font-size: 0.32rem;
          margin-top: 0.15rem;
        }
        .wrongImg {
            padding: 0.3rem;
            box-sizing: border-box;
            box-shadow: 0px 2px 7px 0px rgba(27, 27, 78, 0.1);
            border-radius: 0.15rem;
            margin-top: 0.2rem;
            margin-bottom: 0.2rem;
            .img {
                width: 100%;
                height: auto;
            }
        }
        .select {
            font-size: 0.32rem;
            span {
                margin-right: 0.45rem;
            }
        }
      }
      .intro {
          padding: 0.3rem;
          box-sizing: border-box;
          font-size: 0.36rem;
          font-weight: bold;
          background-color: white;
          .intro-l {
              font-size: 0.32rem;
              font-weight: normal;
              .intro-a {
                  margin-top: 0.1rem;
              }
              .daCn {
                padding: 0.3rem;
                box-sizing: border-box;
                box-shadow: 0px 2px 7px 0px rgba(27, 27, 78, 0.1);
                border-radius: 0.15rem;
                margin-top: 0.2rem;
                .daImg {
                  width: 100%;
                  height: auto;
                  margin-top: 0.15rem;
                }
              }
          }
      }
    }
</style>
