const baseUrl = 'http://42.192.90.32:9092/';

const formatDate = (date, style) => {
    if (date == '') return;
    let m = date.getMonth() + 1 + '';
    let d = date.getDate() + '';
    if(m.length == 1 ){
      m = 0 + m
    }
    if(d.length == 1 ){
      d = 0 + d
    }
    if (style) {
        return date.getFullYear() + '.' + m + '.' + d
    }
    return date.getFullYear() + m + d
}

const tranDate = (str = '') => {
  str = str.toString();
  let date = str.substring(0, 4) + '-' + str.substring(4, 6) + '-' +  str.substring(6, 8);
  return date;
}


//设置时间格式为 yy.mm.dd hh:MM:SS
const formatDate1 = strTime =>{
    let date = new Date(strTime);
    let m = date.getMonth() + 1 + '';
    let d = date.getDate() + '';
    let h = date.getHours() +'';
    let Min = date.getMinutes()+ '';
    let n = date.getSeconds() +'';
    if(m.length == 1 ){
      m = 0 + m
    }
    if(d.length == 1 ){
      d = 0 + d
    }
    if(h.length == 1 ){
      h = 0 + h
    }
    if(Min.length == 1 ){
      Min = 0 + Min
    }
    if(n.length == 1){
      n = 0 + n
    }
    return date.getFullYear()+"-"+ m +"-"+ d + "  " + h+':'+Min + ':' + n;
  }
  
  
  
  const formatDate2 = strTime =>{
    if(!strTime){
      return ""
    }
    let date = new Date(strTime);
    let m = date.getMonth() + 1 + '';
    let d = date.getDate() + '';
    if(m.length == 1 ){
      m = 0 + m
    }
    if(d.length == 1 ){
      d = 0 + d
    }
    return date.getFullYear()+"-"+ m +"-"+ d;
  }


  const upFile = (callback, limit = 1) => {
    let input = document.createElement('input');
    input.type = 'file';
    // input.accept = 'image/*';
    input.name = 'FileContent';
    input.multiple = limit > 1;
    input.style.cssText = 'position: fixed; height:1em; width:1em; opacity:1 ; top:-100em; left:0;';
    input.id = 'from_up';
    document.body.appendChild(input);
    input.onchange = () => {
        if (limit > 1) {
          callback && callback(input.files.slice(0, limit));
        } else {
          callback && callback(input.files[0]);
        }
        
        document.body.removeChild(input);
    };
    input.click();
  }


  const getQuery = (name) => {
    var query =window.location.hash.split('?')[1] || ''
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] == name){
            return pair[1];
        }
    }
    return '';
  }

const copyToDoc = (str = '', callback)  => {
    try {
        const supported = document.queryCommandSupported('copy');
        if (!supported) {
            return false;
        }
        const input = document.createElement('textarea');
        input.value = str;
        input.style.cssText = 'position: absolute; top: -10000px; left: -10000px;';
        document.body.appendChild(input);

        input.setAttribute('readonly', ''); // 避免ios弹出键盘
        input.select();
        input.setSelectionRange(0, input.value.length); // 选中文本
        document.execCommand('copy');
        document.body.removeChild(input);
        callback && callback();
        return true;
    } catch (e) {
        return false;
    }
}
  
  
  export {
    baseUrl,
    tranDate,
    formatDate,
    formatDate1,
    formatDate2,
    upFile,
    getQuery,
    copyToDoc
  }
  
  
  
  