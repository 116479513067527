import { render, staticRenderFns } from "./ModAddress.vue?vue&type=template&id=392b2851&scoped=true&"
import script from "./ModAddress.vue?vue&type=script&lang=js&"
export * from "./ModAddress.vue?vue&type=script&lang=js&"
import style0 from "./ModAddress.vue?vue&type=style&index=0&id=392b2851&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "392b2851",
  null
  
)

export default component.exports