<template>
    <div class="index">
      <Empty title="暂无课程" v-if="courseList.length == 0" />
      <div class="scoll">
        <div class="title" :style="`width: ${this.scollHeight}`">
          <div class="item"
            :id="`item_${index}`"
            v-for="(it, index) in courseMenu" 
            :key="index"
            @click="changeMenu(index, it.id)"
          >
            <p :class="curr == index && 'active'">{{it.courseCateName}}</p>
          </div>
        </div>
      </div>
      <div class="cn">
        <router-link
          :to="`/courseList/${ls.id}`"
          tag="div"
          class="list"
          :key="ls.id"
          v-for="ls in courseList"
        >
          <div class="list-top">
            <span class="top-left">{{ls.courseSeriesName}}</span>
            <span class="top-right">
              课程列表
              <img src="../../image/second/arrow1.png" />
            </span>
          </div>
          <div class="inline"/>
          <div class="bottom">
            <span class="bo-name">课程简介</span>
            <span class="bo-btn" @click="itemArrow(ls, $event)">
              {{ !ls.arrow ? '折叠' : '收起' }}
            </span>
          </div>
          <div class="intro" v-show="ls.arrow">
            <p>课程上传时间：{{ls.gmtStartUpload}}-{{ls.gmtEndUpload}}</p>
            <p>课程有效时间：{{ls.gmtStartEffect}}-{{ls.gmtEndEffect}}</p>
            <p>{{ls.courseSpecial}}</p>
            <p>{{ls.courseEdition}}</p>
            <p>反馈方式：{{ls.feedbackMode}}</p>
            <p>{{ls.content}}</p>
          </div>
        </router-link>

        <!-- <router-link to="/courseList" tag="div" class="list">
          <div class="list-top">
            <span class="top-left">2020年微拍堂大学活动</span>
            <span class="top-right">
              课程列表
              <img src="../../image/second/arrow1.png" />
            </span>
          </div>
          <div class="inline"/>
          <div class="bottom">
            <span class="bo-name">课程简介</span>
            <span class="bo-btn">折叠</span>
          </div>
        </router-link> -->
      </div>
      
    </div>
</template>

<script>
import Empty from '@/components/Empty';
export default {
  components: {
    Empty
  },
  data () {
      return {
        curr: 0,
        scollHeight: '',
        courseMenu: [],
        courseList: [],
      }
  },
  mounted() {
    this.getCourseMenu();
  },
  methods: {
    getCourseMenu() {
      this.$http.get('/api/courseCate/list', {
      })
      .then((res) => {
        this.courseMenu = res.data.data;
        this.getOneList(this.courseMenu[0].id);
        setTimeout(() => {
          console.log(this.courseMenu.length)
          const width = document.getElementById('item_0').offsetWidth;
          this.scollHeight = (this.courseMenu.length) * width / 50 + 'rem'
        }, 1000)
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
    itemArrow(item, $event) {
      item.arrow = !item.arrow;
      this.$forceUpdate();
      $event.preventDefault();
      $event.stopPropagation();
    },
    changeMenu(index, id) {
      this.curr = index;
      this.getOneList(id);
    },
    getOneList(id) {
      this.$http.get(`/api/courseSeries/list/${id}`, {
      })
      .then((res) => {
        let data = res.data.data;
        this.courseList = res.data.data;
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    }
  }

}
</script>

<style lang="less" scoped>
    .index {
      min-height: 100vh;
      background-color: #f4f6f6;
      .scoll {
        height: 1.2rem;
        padding-left: 0.3rem;
        box-sizing: border-box;
        overflow-x: scroll;
        &::-webkit-scrollbar { 
            width: 0;   /* 滚动条宽度， width：对应竖滚动条的宽度  height：对应横滚动条的高度*/
            height: 0;
        }
        .title {
          width: 10rem;
          .item {
            display: inline-block;
            height: 1.2rem;
            line-height: 1.2rem;
            font-size: 0.32rem;
            text-align: center;
            margin-right: 0.1rem;
            p {
              &.active {
                color: #79cdca;
                &::after {
                  position: absolute;
                  width: 60%;
                  bottom: 0.3rem;
                  height: 0.04rem;
                  border-radius: 0.04rem;
                  background-color: #79cdca;
                  left: 50%;
                  transform: translateX(-50%);
                  content: '';
                }
              }
              padding: 0.05rem 0.1rem;
              position: relative;
            }
          }

        }
      }
      .cn {
        padding: 0 0.3rem 0.8rem;
        box-sizing: border-box;
        .list {
          padding: 0 0.2rem;
          box-sizing: border-box;
          background-color: white;
          border-radius: 0.15rem;
          overflow: hidden;
          margin-bottom: 0.25rem;
          .list-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 0.9rem;
            .top-left {
              font-size: 0.3rem;
            }
            .top-right {
              font-size: 0.26rem;
              display: flex;
              align-items: center;
              img {
                width: 0.25rem;
                height: 0.25rem;
                margin-left: 0.06rem;
              }
            }
          }
          .inline {
            border-bottom: 1px solid #d9d9d9;
          }
          .bottom {
            font-size: 0.26rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 0.9rem;
            .bo-name {
              font-size: 0.26rem;
            }
            .bo-btn {

            }
          }
          .intro {
            color: #999;
            font-size: 0.26rem;
            padding-bottom: 0.3rem;
            p {
              line-height: 0.4rem;
            }
          }
        }
      }
      
    }
</style>
