<template>
    <div class="rule">
      <p>1、每日一题答题成功后可获取5积分</p>
      <p>2、视频打卡成功后可获取1积分</p>
      <p>3、作业上传批改后可以获取5积分。</p>
    </div>
</template>

<script>

export default {
  components: {
  },
  data () {
      return {
        active: 0,
      }
  },
  mounted() {
    // console.log(this.props);
  },
  methods: {
    
  }

}
</script>

<style lang="less" scoped>
    .rule {
      min-height: 100vh;
      background-color: #79cdca;
      padding: 0.3rem;
      box-sizing: border-box;
      p {
        color: white;
        font-size: 0.32rem;
        margin-bottom: 0.15rem;
      }
    }
</style>
