<template>
    <div class="modName">
        <div class="mod">
            <input placeholder="请输入昵称" v-model="info.username"/>
        </div>
        <div class="btn" @click="modName">
            保存
        </div>
        <Tips :tip.sync="msg" />
    </div>
</template>

<script>
import { formatDate, baseUrl } from '../../common/util';

export default {
  components: {

  },
  data () {
      return {
        active: 0,
        info: {},
        msg: '',
      }
  },
  mounted() {
    let info = localStorage.getItem('ng-data');
    if (info) {
      this.info = JSON.parse(info);
    }
  },
  methods: {
    modName() {
      this.$http.put('/api/user/update', {
        type: 'username',
        username: this.info.username
      })
      .then((res) => {
        this.msg = '昵称修改成功';
        localStorage.setItem('ng-data', JSON.stringify(this.info));
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .modName {
    min-height: 100vh;
    background-color: #f4f6f6;
    .mod {
        height: 1rem;
        padding-left: 0.3rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
        background-color: white;
        border-bottom: 1px solid #d9d9d9;
        input {
            height: 0.9rem;
            width: 100%;
            font-size: 0.32rem;
        }
    }
    .btn {
        margin-top: 0.45rem;
        width: 100%;
        height: 0.9rem;
        line-height: 0.9rem;
        background-color: #79cdca;
        font-size: 0.35rem;
        color: white;
        text-align: center;
        cursor: pointer;
    }
  }
</style>
