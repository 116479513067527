<template>
    <div class="answer">
      <Empty title="暂无问答" v-if="records.length === 0" />
      <div class="head">
        <div class="nav">
          <p :class="type == 'new' && 'active'" @click="changeType('new')">最新</p>
          <p :class="type == 'hot' && 'active'" @click="changeType('hot')">最热</p>
        </div>
        <div class="upLoad" @click="upAsk"></div>
      </div>

      <div class="loadMore" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
        <div class="list" v-for="it in records" :key="it.id" @click="lookAsk(it.id)">
          <div class="top">
            <img class="headImg" :src="it.avatar" />
            <div class="listInfo">
              <p class="name">{{it.username}}</p>
              <p class="time">{{it.gmtCreate}}</p>
            </div>
          </div>
          <p class="title">{{it.problemTitle}}</p>
          <p class="content">{{it.problemContent}}</p>
          <img class="askImg" :src="it.problemImage"/>
          <div class="detailBtn">
            查看和回复
          </div>
        </div>
      </div>

    </div>
</template>

<script>
import { formatDate, baseUrl } from '../../common/util';
import Empty from '@/components/Empty';
export default {
  name: 'Home',
  components: {
    Empty
  },
  filters: {
    capitalize: function (value) {
      if (!value) return 0
      return value.toFixed(2)
    },
    tranTime: function (val) {
      return val;
    }
  },
  data () {
      return {
        records: [],
        busy: false,
        current: 1,
        size: 10,
        type: 'new',
      }
  },
  mounted() {
    
  },
  methods: {
    lookAsk(id) {
      this.$router.push(`/askDetail/${id}`);
    },
    upAsk() {
      this.$router.push('/askBack');
    },
    changeType(type) {
      this.type = type;
      this.records = [];
      this.busy = false;
      this.current = 1;
      this.loadMore();
    },
    loadMore() {
      this.$http.post('/api/problem/pageList', {
        size: this.size,
        current: this.current,
        type: this.type
      })
      .then((res) => {
        let data = res.data.data;
        if (this.records.length > 0 && data.records.length === 0) {
          this.busy = true;
          return;
        };
        this.records = this.records.concat(data.records);
        this.current ++;
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
  }

}
</script>

<style lang="less" scoped>
  .answer {
    min-height: 100vh;
    background-color: #f4f6f6;
    padding-bottom: 0.5rem;
    .head {
      height: 1rem;
      background-color: #79cdca;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .nav {
        background-color: #8bcbc7;
        display: flex;
        width: 3rem;
        color: white;
        height: 0.7rem;
        line-height: 0.7rem;
        font-size: 0.3rem;
        border: 1px solid white;
        border-radius: 0.7rem;
        align-items: center;
        box-sizing: border-box;
        p {
          height: 100%;
          border-radius: 0.7rem;
          text-align: center;
          flex: 1;
          &.active {
            background-color: white;
            color: #8bcbc7;
          }
        }
      }
      .upLoad {
        position: absolute;
        right: 0.3rem;
        top: 50%;
        transform: translateY(-50%);
        width: 0.45rem;
        height: 0.45rem;
        background-image: url('../../image/task/fabu.png');
        background-size: 100% 100%;
      }
    }
    .loadMore {
      overflow: scroll;
      max-height: calc(100vh -1rem);
    }
    .list {
      background-color: white;
      padding: 0 0.3rem;
      box-sizing: border-box;
      margin-bottom: 0.2rem;
      .top {
        height: 1.2rem;
        display: flex;
        align-items: center;
        .headImg {
          width: 0.8rem;
          height: 0.8rem;
          border-radius: 0.8rem;
          margin-right: 0.3rem;
        }
        .name {
          font-size: 0.3rem;
        }
        .time {
          color: #999999;
        }
      }
      .title {
        font-size: 0.32rem;
        margin-bottom: 0.05rem;
      }
      .content {
        font-size: 0.3rem;
      }
      .askImg {
        width: 100%;
        height: 3rem;
        border-radius: 0.12rem;
        margin: 0.2rem auto 0.3rem;
        box-shadow: 0px 2px 7px 0px rgba(27, 27, 78, 0.1);
        display: block;
        object-fit: cover;
      }
      .detailBtn {
        color: #79cdca;
        border-top: 1px solid #f4f6f6;
        height: 0.8rem;
        line-height: 0.8rem;
        text-align: center;
        font-size: 0.32rem;
      }
    }
  }
</style>
