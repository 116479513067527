<template>
    <div class="work">
        <p class="title" v-show="!id">标题(必选)</p>
        <div class="my-list tit" v-show="!id">
            <input placeholder="请输入内容" v-model="problemTitle"/>
        </div>
        <p class="title">内容(必选)</p>
        <div class="my-list">
            <textarea placeholder="请输入内容" v-model="problemContent"/>
        </div>

        <p class="title">上传图片(仅一张，选填)</p>

        <div class="imgList">
            <img v-show="problemImage" class="img-item" :src="problemImage" />
            <div class="img-item" @click="upImg">
                <img src="../../image/task/upload.png" />
            </div>
        </div>

        <div class="button">
            <div class="btn" @click="submit">提交</div>
        </div>
        <Tips :tip.sync="msg" />

        <Loading v-show="loading" msg="上传中....."/>
    </div>
</template>

<script>
import { formatDate, baseUrl, upFile } from '../../common/util';
export default {
  components: {
    
  },
  data () {
      return {
        id: '',
        problemTitle: '',
        problemContent: '',
        problemImage: '',
        msg: '',
        loading: false,
      }
  },
  mounted() {
      this.id = this.getQuery('id');
  },
  methods: {
    getQuery(name) {
        var query =window.location.hash.split('?')[1]
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == name){
                return pair[1];
            }
        }
        return '';
    },
    upImg() {
        upFile(async (file) => {
            const fileData = new FormData();
            fileData.append('file', file);
            this.loading = true;
            const res = await this.$http.post('/api/file/upload', fileData);
            if (res.data.data) {
                this.msg = '图片上传成功';
                this.problemImage = res.data.data;
                this.loading = false;
            }
        })
    },
    submit() {
        if (!this.problemTitle && !this.id) {
            alert('标题不能为空');
            return;
        }
        if (!this.problemContent) {
            alert('内容不能为空');
            return;
        }
        if (!this.problemImage) {
            alert('图片不能为空');
            return;
        }
        if (!this.id) { // 提交疑问
            this.$http.post('/api/problem/save', {
                problemTitle: this.problemTitle,
                problemContent: this.problemContent,
                problemImage: this.problemImage,
            })
            .then((res) => {
                this.msg = '提交成功';
                setTimeout(() => {
                    this.$router.go(-1);
                })
            }).catch((err) => {
                this.validErrorInfo = err.errorMsg;
            })
        } else { // 提交回复
            this.$http.post('/api/problemReply/save', {
                problemId: this.id,
                problemReplyContent: this.problemContent,
                problemReplyImage: this.problemImage,
            })
            .then((res) => {
                this.msg = '提交成功';
                setTimeout(() => {
                    this.$router.go(-1);
                })
            }).catch((err) => {
                this.validErrorInfo = err.errorMsg;
            })
        }
        
    }

  }

}
</script>

<style lang="less" scoped>
    .work {
        min-height: 100vh;
        background-color: #f4f6f6;
        .title {
            height: 0.7rem;
            padding: 0 0.3rem;
            display: flex;
            align-items: center;
            font-size: 0.26rem;
        }
        .my-list {
            text-decoration: none;
            height: 2.5rem;
            padding: 0 0.3rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #d9d9d9;
            background-color: white;
            position: relative;
            color: #333333;
            &.tit {
                height: 1rem;
            }
            input {
                height: 0.95rem;
                width: 92%;
                font-size: 0.3rem;
            }
            textarea {
                height: 1.98rem;
                width: 92%;
                font-size: 0.3rem;
            }
        }
        .imgList {
            background-color: white;
            padding: 0.3rem;
            box-sizing: border-box;
            display: flex;
            .img-item {
                width: 1.8rem;
                height: 1.8rem;
                border-radius: 0.12rem;
                background-color: #f4f6f6;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 0.3rem;
                img {
                    display: block;
                    margin: 0 auto;
                    width: 0.7rem;
                    height: 0.7rem;
                }
            }
        }
        .button {
            padding: 0 0.3rem;
            box-sizing: border-box;
            margin: 0.5rem auto;
            border-radius: 0.12rem;
            color: white;
            overflow: hidden;
            .btn {
                background-color: #79cdca;
                width: 100%;
                height: 0.9rem;
                border-radius: 0.12rem;
                text-align: center;
                line-height: 0.9rem;
                font-size: 0.35rem;
                cursor: pointer;
            }
        }
    }
</style>
