<template>
    <div class="setting">
      <router-link class="my-list" to="/about" tag="div">
        <span class="li-left">
          <span class="word">关于</span>
        </span>
        <i class="list-arrow" />
      </router-link>
      <router-link class="my-list" to="/modPassword" tag="div">
        <span class="li-left">
          <span class="word">修改密码</span>
        </span>
        <i class="list-arrow" />
      </router-link>
      <div class="btn" @click="exit">
        退出
      </div>
    </div>
</template>

<script>
import { formatDate, baseUrl } from '../../common/util';

export default {
  components: {
    
  },
  data () {
    return {
      active: 0,
    }
  },
  mounted() {
    
  },
  methods: {
    exit() {
        this.$http.put('api/logout', {
        })
        .then((res) => {
            localStorage.removeItem('ng-data');
            localStorage.removeItem('ng-token');
            setTimeout(() => {
              this.$router.push('/login');
            })
        }).catch((err) => {
            this.validErrorInfo = err.errorMsg;
        })
    }
  }

}
</script>

<style lang="less" scoped>
  .setting {
    .my-list {
      &.space {
        margin-top: 0.2rem;
      }
      height: 1.09rem;
      padding: 0 0.3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #d9d9d9;
      background-color: white;
      position: relative;
      cursor: pointer;
      .li-left {
        display: flex;
        align-items: center;
        img {
          width: 0.45rem;
          height: 0.45rem;
          display: inline-block;
          margin-right: 0.3rem;
        }
        .word {
          font-size: 0.32rem;
        }
      }
      .list-arrow {
          // position: absolute;
          // right: 0.3rem;
          // top: 50%;
          // transform: translateY(-50%);
          width: 0.39rem;
          height: 0.39rem;
          background-image: url('../../image/second/arrow1.png');
          background-size: 100% 100%;
          display: block;
        }
    }
    .btn {
        margin-top: 0.45rem;
        width: 100%;
        height: 0.9rem;
        line-height: 0.9rem;
        background-color: #79cdca;
        font-size: 0.35rem;
        color: white;
        text-align: center;
        cursor: pointer;
      }
  }
</style>
