<template>
  <div class="address">
      <vue-picker 
        class="picker"
        :data="pickData"
        @cancel="cancel"
        @confirm="confirm"
        :showToolbar="true"
        :maskClick="true"
        :visible.sync="pickerVisible"
      />
      <div class="my-list" @click="pickerOpen(1)">
          <span class="li-left">
          <span class="word">省份</span>
          <span class="word1">{{info.province}}</span>
          </span>
          <i class="list-arrow" />
      </div>
      <div class="my-list" @click="pickerOpen(2)">
          <span class="li-left">
          <span class="word">城市</span>
          <span class="word1">{{info.city}}</span>
          </span>
          <i class="list-arrow" />
      </div>
      <div class="my-list" @click="pickerOpen(3)">
          <span class="li-left">
          <span class="word">区县</span>
          <span class="word1">{{info.area}}</span>
          </span>
          <i class="list-arrow" />
      </div>
      <div class="btn" @click="saveAddress">
          保存
      </div>
      <Tips :tip.sync="msg" />
  </div>
</template>

<script>
import { formatDate, baseUrl } from '../../common/util';
// import Empty from '@/components/Empty';

export default {
  components: {

  },
  data () {
      return {
        pickerVisible: false,
        pickData: [],
        info: {
          provinceCode: '',
          cityCode: '',
          areaCode: '',
          province: '',
          city: '',
          area: '',
        },
        provinceCodeList: [],
        cityCodeList: [],
        areaCodeList: [],
        currId: 1,
        msg: ''
      }
  },
  mounted() {
    let info = localStorage.getItem('ng-data');
    if (info) {
      this.info = JSON.parse(info);
      this.getData(1, 1);
      this.getData(this.info.provinceCode, 2);
      this.getData(this.info.cityCode, 3);
    }
  },
  methods: {
    getData(id = 1, currId = 1) {
      this.$http.get(`/anonymous/sysCity/getCityListByParentCode//${id}`, {
      })
      .then((res) => {
        if (currId == 1) {
          this.provinceCodeList = res.data.data;
        } else if (currId == 2){
          this.cityCodeList = res.data.data;
        } else if (currId == 3){
          this.areaCodeList = res.data.data;
        }
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
    pickerOpen(id) {
      let tdata = [];
      let data = [];
      if (id === 1) {
        data = this.provinceCodeList;
      }
      if (id === 2) {
        data = this.cityCodeList;
      }
      if (id === 3) {
        data = this.areaCodeList;
      }
      // 我的微信账号15057060226
      // 密码：xuxiaofei
      // 或者xuxiaofei9460
      this.currId = id;
      for (let i = 0; i< data.length; i ++) {
        tdata.push({
          label: data[i]?.name || data[i],
          value: data[i]?.code || data[i],
        })
      }
      this.pickData = [ tdata ];
      this.pickerVisible = true;
    },
    cancel () {
      console.log('cancel')
      this.result = 'click cancel result: null'
    },
    confirm (res) {
      if (this.currId === 1) {
        this.info.provinceCode = res[0].value;
        this.info.province = res[0].label;
        this.info.cityCode = '';
        this.info.city = '';
        this.info.areaCode = '';
        this.info.area = ''
        this.getData(res[0].value, 2)
      } else if (this.currId === 2) {
          this.info.cityCode = res[0].value;
          this.info.city = res[0].label;
          this.info.areaCode = '';
          this.info.area = ''
          this.getData(res[0].value, 3)
      } else if (this.currId === 3) {
          this.info.area = res[0].label;
          this.info.areaCode = res[0].value;
      }
    },
    saveAddress() {
    if (this.info.province === '') {
      alert('省份不能为空');
      return;
    }
    if (this.info.city === '') {
      alert('城市不能为空');
      return;
    }
    if (this.info.area === '') {
      alert('地区不能为空');
      return;
    }
    this.$http.put('/api/user/update', {
        type: 'area',
        areaCode: this.info.areaCode
      })
      .then((res) => {
        this.msg = '地址修改成功';
        localStorage.setItem('ng-data', JSON.stringify(this.info));
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    }
  },
  
}
</script>

<style lang="less" scoped>
  .address {
    min-height: 100vh;
    background-color: #f4f6f6;
    .my-list {
        &.space {
          margin-top: 0.2rem;
        }
        height: 1.09rem;
        padding: 0 0.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d9d9d9;
        background-color: white;
        position: relative;
        cursor: pointer;
        .li-left {
          display: flex;
          align-items: center;
          font-size: 0.32rem;
          img {
            width: 0.45rem;
            height: 0.45rem;
            display: inline-block;
            margin-right: 0.3rem;
          }
          .word {
            font-size: 0.32rem;
          }
          .word1 {
              margin-left: 2rem;
          }
        }
        .li-right {
          display: flex;
          font-size: 0.32rem;
          align-items: center;
        }
        .list-arrow {
            // position: absolute;
            // right: 0.3rem;
            // top: 50%;
            // transform: translateY(-50%);
            width: 0.39rem;
            height: 0.39rem;
            background-image: url('../../image/second/arrow1.png');
            background-size: 100% 100%;
            display: block;
        }
      }
      .btn {
        margin-top: 0.45rem;
        width: 100%;
        height: 0.9rem;
        line-height: 0.9rem;
        background-color: #79cdca;
        font-size: 0.35rem;
        color: white;
        text-align: center;
        cursor: pointer;
      }
  }
</style>
