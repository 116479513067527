<template>
    <div class="work">
      <p class="title">年级类型</p>
      <div class="my-list">
        <span class="li-left">
          <span class="word">{{info.courseCateName}}</span>
        </span>
        <i />
      </div>

      <p class="title">系列名称</p>
      <div class="my-list">
        <span class="li-left">
          <span class="word">{{info.courseSeriesName}}</span>
        </span>
        <i />
      </div>

      <p class="title">课程名称</p>
      <div class="my-list">
        <span class="li-left">
          <span class="word">{{info.courseName}}</span>
        </span>
        <i />
      </div>

      <div class="imgList">
         <!-- <img class="img-item" src="" /> -->
          <div class="img-item" v-for="(it, index) in info.workImage" :key="index">
            <ImageShow class="has" :src="it" />
          </div>
      </div>

    </div>
</template>

<script>
import { getQuery, baseUrl, upFile } from '../../common/util';
import ImageShow from '@/components/ImageShow';
export default {
  components: {
    ImageShow
  },
  data () {
      return {
        id: '',
        workImage: [], // 图片
        info: {
        }
      }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getData(this.id);
  },
  methods: {
    getData(id) {
      this.$http.get(`/api/courseWork/${id}`, {
      })
      .then((res) => {
        this.info = res.data.data;
        this.info.workImage = JSON.parse(this.info.workImage);
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
  }

}
</script>

<style lang="less" scoped>
    .work {
      min-height: 100vh;
      background-color: #f4f6f6;
      .title {
        height: 0.7rem;
        padding: 0 0.3rem;
        display: flex;
        align-items: center;
        font-size: 0.26rem;
      }
      .my-list {
        &.space {
          margin-top: 0.2rem;
        }
        text-decoration: none;
        height: 1.09rem;
        padding: 0 0.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d9d9d9;
        background-color: white;
        position: relative;
        color: #333333;
        cursor: pointer;
        .li-left {
          display: flex;
          align-items: center;
          img {
            width: 0.45rem;
            height: 0.45rem;
            display: inline-block;
            margin-right: 0.3rem;
          }
          .word {
            font-size: 0.32rem;
          }
        }
        .list-arrow {
            // position: absolute;
            // right: 0.3rem;
            // top: 50%;
            // transform: translateY(-50%);
            width: 0.39rem;
            height: 0.39rem;
            background-image: url('../../image/second/arrow1.png');
            background-size: 100% 100%;
            display: block;
          }
      }
      .imgList {
        background-color: white;
        padding: 0.3rem;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        .img-item {
          width: 1.8rem;
          height: 1.8rem;
          border-radius: 0.12rem;
          background-color: #f4f6f6;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.3rem;
          cursor: pointer;
          position: relative;
          margin-bottom: 0.2rem;
          .close {
            position: absolute;
            top: 0;
            right: 0;
            width: 0.3rem;
            height: 0.3rem;
            background-image: url('../../image/task/close.png');
            background-size: 100% 100%;
          }
          .has {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 0.12rem;
          }
          img {
            display: block;
            margin: 0 auto;
            width: 0.7rem;
            height: 0.7rem;
          }
        }
      }
      .button {
        padding: 0 0.3rem;
        box-sizing: border-box;
        margin: 0.5rem auto;
        border-radius: 0.12rem;
        color: white;
        overflow: hidden;
        .btn {
          background-color: #79cdca;
          width: 100%;
          height: 0.9rem;
          border-radius: 0.12rem;
          text-align: center;
          line-height: 0.9rem;
          font-size: 0.35rem;
          cursor: pointer;
        }
      }
    }
</style>
