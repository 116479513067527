<template>
    <div class="history">
      <Empty title="暂无兑换记录" v-if="records.length === 0" />
      <div class="list">
        <div class="item" v-for="it in records" :key="it.id">
          <img :src="it.commodityImage" />
          <div class="cn">
            <p class="it-name">{{it.commodityName}}</p>
            <p class="it-num">兑换状态: {{it.status === 0 ? '待发货' : '已发货'}}</p>
            <div class="it-bo">
              <span class="bo-left">
                <span>使用积分: {{it.integral}}</span>
              </span>
            </div>
            <div class="it-bo">
              <span class="bo-left">
                <span>时间：{{it.gmtCreate}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="list">
        <div class="item">
          <img src="../../image/second/airplan.png" />
          <div class="cn">
            <p class="it-name">无人机</p>
            <p class="it-num">兑换状态: 已发货</p>
            <div class="it-bo">
              <span class="bo-left">
                <span>使用积分: 5000</span>
              </span>
              <span>2020-11-23</span>
            </div>
          </div>
        </div>

        <div class="item">
          <img src="../../image/second/airplan.png" />
          <div class="cn">
            <p class="it-name">无人机</p>
            <p class="it-num">兑换状态: 待发货</p>
            <div class="it-bo">
              <span class="bo-left">
                <span>使用积分: 5000</span>
              </span>
              <span>2020-11-23</span>
            </div>
          </div>
        </div>

        <div class="item">
          <img src="../../image/second/airplan.png" />
          <div class="cn">
            <p class="it-name">无人机</p>
            <p class="it-num">兑换状态: 待发货</p>
            <div class="it-bo">
              <span class="bo-left">
                <span>使用积分: 5000</span>
              </span>
              <span>2020-11-23</span>
            </div>
          </div>
        </div>
        
      </div> -->

    </div>
</template>

<script>

import Empty from '@/components/Empty';
export default {
  components: {
    Empty
  },
  data () {
      return {
        active: 0,
        records: [],
        busy: false,
        current: 1,
      }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http.get('/api/commodityExchangeRecord/list', {
      })
      .then((res) => {
        this.records = res.data.data;
      }).catch((err) => {
        this.validErrorInfo = err.errorMsg;
      })
    },
  }

}
</script>

<style lang="less" scoped>
  .history {
    min-height: 100vh;
    background-color: #f4f6f6;
    .list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0.3rem 0.3rem 0.5rem;
      box-sizing: border-box;
      .item {
        background: white;
        color: #999999;
        width: 3.35rem;
        box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.03);
        border-radius: 0.12rem;
        overflow: hidden;
        margin-bottom: 0.2rem;
        img {
          width: 100%;
          height: 2.79rem;
        }
        .cn {
          padding: 0 0.15rem 0.2rem;
          box-sizing: border-box;
        }
        .it-name {
          font-size: 0.28rem;
          color: #333333;
        }
        .it-num {
          font-size: 0.24rem;
          margin-top: 0.05rem;
        }
        .it-bo {
          font-size: 0.24rem;
          margin: 0 auto 0.05rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .bo-left {
            .hai {
              margin-left: 0.05rem;
            }
          }
          .bo-right {
            background-color: #79cdca;
            color: white;
            padding: 0.05rem 0.15rem;
            border-radius: 0.2rem;
          }
        }
      }
    }
  }
</style>
