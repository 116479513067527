<template>
    <div>
       <img :src="src" class="fix" @click="show = true"/>
       <div v-show="show" @click="show =!show">
           <div class="bg bg1" />
           <img v-if="show" :src="src" class="large" />
       </div>
    </div>
</template>

<script>

export default {
  props: ['src'],
  components: {
    
  },
  data () {
      return {
        show: false,
      }
  },
  mounted() {

  },
  methods: {
  }

}
</script>

<style lang="less" scoped>
    .fix {
        width: 100%;
        height: 100%;
    }
    .bg1 {
        background-color: rgba(0,0,0, 1);
    }
    .large {
        position: fixed;
        width: 100%;
        max-width: 7.5rem;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
    }
</style>
