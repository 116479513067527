<template>
    <div class="work">
      <vue-picker 
        class="picker"
        :data="pickData"
        @cancel="cancel"
        @confirm="confirm"
        :showToolbar="true"
        :maskClick="true"
        :visible.sync="pickerVisible"
      />
      <p class="title">选择年级类型(必选)</p>
      <div class="my-list" @click="pickerOpen(1)">
        <span class="li-left">
          <span class="word">{{name1}}</span>
        </span>
        <i class="list-arrow" />
      </div>

      <p class="title">选择系列(必选)</p>
      <div class="my-list"  @click="pickerOpen(2)">
        <span class="li-left">
          <span class="word">{{name2}}</span>
        </span>
        <i class="list-arrow" />
      </div>

      <p class="title">选择课程(必选)</p>
      <div class="my-list"  @click="pickerOpen(3)">
        <span class="li-left">
          <span class="word">{{name3}}</span>
        </span>
        <i class="list-arrow" />
      </div>

      <div class="imgList">
         <!-- <img class="img-item" src="" /> -->
          <div class="img-item" v-for="(it, index) in workImage" :key="index">
            <img class="has" :src="it" />
            <i class="close" @click="deleteImg(index)"/>
          </div>
          <div class="img-item" v-show="workImage.length != 9" @click="upImg">
            <img src="../../image/task/upload.png" />
          </div>
      </div>

      <div class="button">
        <div class="btn" @click="submit">上传</div>
      </div>
      <Tips :tip.sync="msg" />
      <Loading v-show="loading" msg="上传中....."/>
    </div>
</template>

<script>
import { getQuery, baseUrl, upFile } from '../../common/util';
export default {
  components: {
    
  },
  data () {
      return {
        id: '',
        workImage: [], // 图片
        pickerVisible: false, 
        pickData: [],
        list1: [], // 年级类型
        name1: '',
        list2: [], // 选择系列
        name2: '',
        list3: [], // 选择课程
        name3: '',
        courseId: '',
        curr: 1,
        msg: '',
        loading: false
      }
  },
  mounted() {
    this.getCourse1();
  },
  methods: {
    getCourse1(id) {
      this.$http.get(`/api/courseCate/list`, {
      })
      .then((res) => {
        this.list1 = res.data.data;
        this.name1 = this.list1[0].courseCateName;
        this.getCourse2(this.list1[0].id);
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
    getCourse2(id) {
      this.$http.get(`/api/courseSeries/list/${id}`, {
      })
      .then((res) => {
        this.list2 = res.data.data;
        if (this.list2.length === 0) {
          this.name2 = '暂无该系列';
          this.name3 = '暂无该系列';
          this.courseId = '';
          return;
        }
        this.name2 = this.list2[0].courseSeriesName;
        this.getCourse3(this.list2[0].id);
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
    getCourse3(id) {
      this.$http.get(`api/course/list/${id}`, {
      })
      .then((res) => {
        this.list3 = res.data.data;
        if (this.list3.length === 0) {
          this.name3 = '暂无该系列';
          this.courseId = '';
          return;
        }
        this.name3 = this.list3[0].courseName;
        this.courseId = this.list3[0].id;
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
    cancel () {
      console.log('cancel')
      this.result = 'click cancel result: null'
    },
    pickerOpen(id) {
      let tdata = [];
      let data = [];
      if (id === 1) {
        this.list1.forEach(it => {
          tdata.push({
            label: it.courseCateName,
            value: it.id
          })
        })
      }
      if (id === 2) {
        this.list2.forEach(it => {
          tdata.push({
            label: it.courseSeriesName,
            value: it.id
          })
        })       
      }
      if (id === 3) {
        this.list3.forEach(it => {
          tdata.push({
            label: it.courseName,
            value: it.id
          })
        })        
      }
      this.curr = id;
      this.pickData = [ tdata ];
      this.pickerVisible = true;
    },
    deleteImg(index) {
      this.workImage.splice(index, 1)
    },
    confirm (res) {
      // this.provinceCode = res[0].value;
      // this.province = res[0].label;
      if (this.curr == 1) {
        this.name1 = res[0].label;
        this.getCourse2(res[0].value)
      }
      if (this.curr == 2) {
        this.name2 = res[0].label;
        this.getCourse3(res[0].value)
      }
      if (this.curr == 3) {
        this.name3 = res[0].label;
        this.courseId = res[0].value;
      }
    },
    upImg() {
      upFile(async (file) => {
        const fileData = new FormData();
        fileData.append('file', file);
        this.loading = true;
        const res = await this.$http.post('/api/file/upload', fileData);
        if (res.data.data) {
          this.loading = false;
          this.msg = '图片上传成功';
          this.workImage.push(res.data.data);
        }
      })
    },
    submit() {
      if (!this.courseId) {
        alert('请选择课程');
        return;
      }
      if (this.workImage.length === 0) {
        alert('请至少上传一张图片');
        return;
      }
      this.$http.post(`/api/courseWork/save`, {
        courseId: this.courseId,
        workImage: JSON.stringify(this.workImage)
      })
      .then((res) => {
        this.msg = '作业上传成功';
        this.$router.go(-1);
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    }
  }

}
</script>

<style lang="less" scoped>
    .work {
      min-height: 100vh;
      background-color: #f4f6f6;
      .title {
        height: 0.7rem;
        padding: 0 0.3rem;
        display: flex;
        align-items: center;
        font-size: 0.26rem;
      }
      .my-list {
        &.space {
          margin-top: 0.2rem;
        }
        text-decoration: none;
        height: 1.09rem;
        padding: 0 0.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d9d9d9;
        background-color: white;
        position: relative;
        color: #333333;
        cursor: pointer;
        .li-left {
          display: flex;
          align-items: center;
          img {
            width: 0.45rem;
            height: 0.45rem;
            display: inline-block;
            margin-right: 0.3rem;
          }
          .word {
            font-size: 0.32rem;
          }
        }
        .list-arrow {
            // position: absolute;
            // right: 0.3rem;
            // top: 50%;
            // transform: translateY(-50%);
            width: 0.39rem;
            height: 0.39rem;
            background-image: url('../../image/second/arrow1.png');
            background-size: 100% 100%;
            display: block;
          }
      }
      .imgList {
        background-color: white;
        padding: 0.3rem;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        .img-item {
          width: 1.8rem;
          height: 1.8rem;
          border-radius: 0.12rem;
          background-color: #f4f6f6;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.3rem;
          cursor: pointer;
          position: relative;
          margin-bottom: 0.2rem;
          .close {
            position: absolute;
            top: 0;
            right: 0;
            width: 0.3rem;
            height: 0.3rem;
            background-image: url('../../image/task/close.png');
            background-size: 100% 100%;
          }
          .has {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 0.12rem;
          }
          img {
            display: block;
            margin: 0 auto;
            width: 0.7rem;
            height: 0.7rem;
          }
        }
      }
      .button {
        padding: 0 0.3rem 0.5rem;
        box-sizing: border-box;
        margin: 0.5rem auto;
        border-radius: 0.12rem;
        color: white;
        overflow: hidden;
        .btn {
          background-color: #79cdca;
          width: 100%;
          height: 0.9rem;
          border-radius: 0.12rem;
          text-align: center;
          line-height: 0.9rem;
          font-size: 0.35rem;
          cursor: pointer;
        }
      }
    }
</style>
