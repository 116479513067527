<template>
    <div class="course">
      <video
        :controls="active"
        ref="$video" 
        :src="info.videoUrl"
      />
      <div class="videoBg" v-if="!active">
      </div>
      <div class="title">
        <div class="tl-top">
          <span class="name">{{info.courseSeriesName}}</span>
          <span class="class">{{info.courseName}}</span>
        </div>
        <p class="time">教材时间：{{info.gmtStartEffect}} 至 {{info.gmtEndEffect}}</p>
      </div>

      <div class="intro">
        <p class="in-name">本教材简介</p>
        <p>{{info.synopsis}}</p>
      </div>

      <div class="intro">
        <p class="in-name">系列教材信息</p>

        <p>系列教材：{{info.courseSeriesName}}</p>
        <p>系列价格：¥{{info.courseSeriesPrice}}</p>
        <p>课程上传时间：{{info.gmtStartUpload | timeTran}} — {{info.gmtEndUpload | timeTran}}</p>
        <p>课程有效时间：{{info.gmtStartEffect | timeTran}} — {{info.gmtEndEffect | timeTran}}</p>
        <p>{{info.courseSpecial}}</p>
        <p>{{info.courseEdition}}</p>
        <p>反馈方式：{{info.feedbackMode}}</p>
        <p>{{info.content}}</p>
      </div>
      
      <div class="immBtn" v-show="info.isPayment !== 1 && !click" @click="playVideo">立即播放</div>

      <div class="hasPlay" v-show="info.isPayment !== 1 && click">
        <div class="Btn" v-show="!play" @click="playVideo">继续播放</div>
        <div class="Btn" v-show="play" @click="playVideo">暂停</div>
        <div class="daka" @click="daka">打卡</div>
      </div>

      <div class="buyBtn" v-if="info.isPayment === 1">
        <div class="Btn" @click="buy(1)">
          <div>¥{{info.coursePrice}}</div>
          <div>购买本教材</div>
        </div>
        <div class="Btn" @click="buy(2)">
          <div>¥{{info.courseSeriesPrice}}</div>
          <div>购买本系列</div>
        </div>
        <div class="Btn" @click="buy(3)">
          <div>¥{{info.courseSeriesGroupPrice}}</div>
          <div>团购本系列</div>
        </div>
      </div>

      <Tips :tip.sync="msg" /> 
    </div>
</template>

<script>

export default {
  data () {
      return {
        active: false,
        id: 0,
        play: false,
        click: false,
        info: {},
        msg: ''
      }
  },
  filters: {
    timeTran: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.substring(0, 10)
    }
  },
  mounted() {
    // console.log(this.props);
    this.id = this.$route.params.id;
    this.getDetail();
    this.$nextTick(() => {
      this.$refs.$video.addEventListener('play', this.initPlay);
      this.$refs.$video.addEventListener('pause', this.initPause);
      this.$refs.$video.addEventListener('ended', this.initEnd);
    })
  },
  unmounted() {
    this.$refs.$video.removeEventListener('play', this.initPlay);
    this.$refs.$video.removeEventListener('pause', this.initPause);
    this.$refs.$video.removeEventListener('ended', this.initEnd);
  },
  methods: {
    initPlay() {
      console.log("开始播放");
      this.play = true;
      this.click = true;
    },
    initPause() {
      console.log("暂停播放");
      this.play = false;
    },
    initEnd() {
      console.log("播放结束");
      this.play = false;
    },
    playVideo() {
      if (this.info.isPayment == 1) {
        return;
      }
      if (!this.play) {
        this.$refs.$video.play();
      } else {
        this.$refs.$video.pause();
      }
    },
    getInfo() {
      this.$http.get(`/api/user/getInfo`, {
      })
      .then((res) => {
        localStorage.setItem('ng-data', JSON.stringify(res.data.data));
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
    daka() {
      this.$http.put(`/api/course/watchCourseVideo/${this.id}`, {
      })
      .then((res) => {
        this.msg = '打卡成功';
        this.getInfo();
      }).catch((err) => {
        this.validErrorInfo = err.errorMsg;
      })
    },
    getDetail() {
      this.$http.get(`/api/course/getCourseInfo/${this.id}`, {
      })
      .then((res) => {
        this.info = res.data.data;
        if (this.info.isPayment == 1) {
          if (this.info.purchasedCourse == 1) {
            this.info.isPayment = 2
          }
        }
        this.active = this.info.isPayment != 1;
       
      }).catch((err) => {
        this.validErrorInfo = err.errorMsg;
      })
    },
    buy(type) {
      this.$router.push(`/CourseSend/${this.id}?type=${type}`)
      // alert('请到明九章app上购买')
    }
  }

}
</script>

<style lang="less" scoped>
    .course {
      min-height: 100vh;
      background-color: #f4f6f6;
      padding-bottom: 0.5rem;
      box-sizing: border-box;
      position: relative;
      video {
        width: 100%;
        height: 4rem;
        display: block;
      }
      .videoBg {
        position: absolute;
        top: 0;
        left: 0;
        height: 4rem;
        width: 100%;
        background-image: url('../../image/course/videoBg.png');
        background-size: cover;
      }
      .title {
        background-color: white;
        padding-left: 0.3rem;
        box-sizing: border-box;
        .tl-top {
          padding-top: 0.3rem;
          display: flex;
          align-items: center;
          .name {
            color: #ed6636;
            padding: 0.05rem 0.1rem;
            border: 1px solid  #ed6636;
            border-radius: 0.12rem;
            margin-right: 0.15rem;
            font-size: 0.24rem;
          }
          .class {
            font-size: 0.32rem;
          }
        }
        .time {
          color: #999;
          margin-top: 0.1rem;
          padding-bottom: 0.2rem;
          font-size: 0.24rem;
        }
      }
      .intro {
        margin-top: 0.2rem;
        background-color: white;
        padding: 0.2rem 0.3rem;
        box-sizing: border-box;
        .in-name {
          font-size: 0.36rem;
          margin-bottom: 0.15rem;
          position: relative;
          &::after {
            position: absolute;
            bottom: -0.03rem;
            left: 0.35rem;
            height: 0.04rem;
            border-radius: 0.04rem;
            width: 0.8rem;
            background-color: #ed6636;
            content: '';
          }
        }
        p {
          font-size: 0.32rem;
        }
      }
      .immBtn {
        width: 3rem;
        height: 1rem;
        line-height: 1rem;
        font-size: 0.32rem;
        background-color: #79cdca;
        color: white;
        margin: 0.5rem auto 0;
        border-radius: 1rem;
        text-align: center;
      }
      .hasPlay {
        margin: 0.5rem auto 0;
        display: flex;
        justify-content: center;
        .Btn {
          width: 3rem;
          height: 1rem;
          line-height: 1rem;
          font-size: 0.32rem;
          background-color: #79cdca;
          color: white;
          border-radius: 1rem;
          text-align: center;
        }
        .daka {
          background-color: #ed6636;
          line-height: 1rem;
          font-size: 0.32rem;
          border-radius: 1rem;
          width: 1.5rem;
          text-align: center;
          color: white;
          margin-left: 0.3rem;
        }
      }
      .buyBtn {
        margin: 0.5rem auto 0;
        display: flex;
        background-image: linear-gradient(to right, #f0bf5a, #e8343a);
        height: 1rem;
        width: 6.5rem;
        border-radius: 1rem;
        .Btn {
          color: white;
          font-size: 0.32rem;
          width: 33.33%;
          text-align: center;
          border-right: 1px solid white;
          &:last-child {
            border-right: none;
          }
        }
      }
    }
</style>
