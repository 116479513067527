import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from './common/axios'

import vuePickers from 'vue-pickers'

import BaseCom from './components/Common';

import infiniteScroll from 'vue-infinite-scroll';

// import Vant from 'vant';
// import 'vant/lib/index.css';

// localStorage.setItem('ng-token','api_eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MDgzNTQzOTYsInVzZXJJZCI6MSwiaWF0IjoxNjA3NzQ5NTk2LCJ1c2VybmFtZSI6InpsZyJ9.lUc00ztwkT8iI-ufa38a_R1CW_XrMWMFHkijUPGKRYs')

Vue.use(vuePickers);

Vue.use(BaseCom);

Vue.use(infiniteScroll);


import './main.less';

Vue.config.productionTip = false

Vue.prototype.$http = axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
