<template>
    <div class="about">
      <div class="top">
        <img class="icon" src="../../image/second/icon.png" />
        <p class="name">明九章App</p>
        <p class="version">32.9M Version 1.0.7</p>
      </div>
      <div class="info">
        <p>应用简介</p>
        明九章是一款多功能的安卓app，提供丰富的观看、操作、测试的系统功能，操作简单，致力于传播文化，积极推广网上课程，促进下载用户的共同成长。
      </div>
      <div class="btn" @click="downLoad">点击下载</div>
      <router-link to="/" tag="div" class="back"/>
    </div>
</template>

<script>

export default {
  components: {
    
  },
  data () {
    return {
      active: 0,
    }
  },
  mounted() {
    
  },
  methods: {
    downLoad() {
        // window.location.href = 'https://mobile.baidu.com/item?docid=28026216&source=mobres&from=1010680m'
        window.location.href = `http://www.mingjiuzhang.cn/app-release.apk?time=${new Date().getTime()}`;
    }
  }

} 
</script>

<style lang="less" scoped>
  .about {
    min-height: 100vh;
    padding-bottom: 1.5rem;
    box-sizing: border-box;
    background-color: #f4f6f6;
    .top {
      padding-top: 2rem;
      text-align: center;
      .icon {
        width: 1.2rem;
        height: 1.2rem;
        display: block;
        margin: 0 auto;
        border-radius: 0.2rem;
        box-shadow: 0px 2px 7px 0px rgba(27, 27, 78, 0.1);
      }
      .name {
        color: #333333;
        font-size: 0.35rem;
        margin: 0.1rem auto 0;
        font-weight: bold;
      }
      .version {
        font-size: 0.28rem;
        color: #cccccc;
        margin-bottom: 0.1rem;
      }
    }
    .info {
        padding: 0 0.6rem;
        box-sizing: border-box;
        font-size: 0.3rem;
        color: #333333;
        margin-top: 0.5rem;
        p {
            font-weight: bold;
            font-size: 0.32rem;
            margin-bottom: 0.3rem;
        }
    }
    .btn {
        background-color: #3883f7;
        height: 0.75rem;
        line-height: 0.75rem;
        width: 3rem;
        color: white;
        margin: 0.5rem auto;
        font-size: 0.3rem;
        font-weight: bold;
        text-align: center;
        border-radius: 0.12rem;
    }
  }
  .back {
      position: fixed;
      right: 0.3rem;
      bottom: 2rem;
      background-image: url('../../image/menu/back1.png');
      background-size: 100% 100%;
      width: 0.9rem;
      height: 0.9rem;
      display: block;
    }
</style>
