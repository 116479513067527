<template>
    <div class="person">
      <div class="head">
          <img @click="modImg" v-if="!info.avatar" class="touxiang" src="../../image/first/head.jpg" />
          <img @click="modImg" v-if="info.avatar" class="touxiang" :src="info.avatar" />
          <p>更换头像</p>
      </div>

      <router-link class="my-list" to="/modName" tag="div">
        <span class="li-left">
          <span class="word">昵称</span>
        </span>
        <span class="li-right">
          {{info.username}}
          <i class="list-arrow" />
        </span>

      </router-link>

      <div class="my-list" v-if="info.identity =='学生'">
        <span class="li-left">
          <span class="word">年级</span>
        </span>
        <span class="li-right">{{info.grade}}</span>
      </div>

      <div class="my-list" v-if="info.identity =='老师'">
        <span class="li-left">
          <span class="word">类型</span>
        </span>
        <span class="li-right">{{info.identity}}</span>
      </div>

      <router-link class="my-list" to="/modAddress" tag="div">
        <span class="li-left">
          <span class="word">常住地</span>
        </span>
        <i class="list-arrow" />
      </router-link>
      <Tips :tip.sync="msg" />
      <Loading v-show="loading" msg="上传中....."/>
    </div>
</template>

<script>
import { formatDate, baseUrl, upFile } from '../../common/util';

export default {
  components: {
    
  },
  data () {
      return {
        active: 0,
        info: {
        },
        msg: '',
        loading: false
      }
  },
  mounted() {
    let info = localStorage.getItem('ng-data');
    if (info) {
      this.info = JSON.parse(info);
    }
  },
  methods: {
    modImg() {
      upFile(async (file) => {
        const fileData = new FormData();
        fileData.append('file', file);
        this.loading = true;
        const res = await this.$http.post('/api/file/upload', fileData);
        if (res.data.data) {
          this.loading = false;
          const resMod = await this.$http.put('/api/user/update', {
            type: 'avatar',
            avatar: res.data.data
          });
          if (resMod.data.code == 200) {
            this.msg = '头像修改成功';
            this.info.avatar = res.data.data;
            localStorage.setItem('ng-data', JSON.stringify(this.info)) 
          }
        }
      })
    }
  }

}
</script>

<style lang="less" scoped>
  .person {
    min-height: 100vh;
    background-color: #f4f6f6;
    .head {
      height: 2.5rem;
      text-align: center;
      padding-top: 0.5rem;
      box-sizing: border-box;
      cursor: pointer;
      .touxiang {
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        margin: 0 auto;
        border-radius: 1.2rem;
      }
      p {
        font-size: 0.32rem;
        margin-top: 0.2rem;
      }
    }
    .my-list {
        &.space {
          margin-top: 0.2rem;
        }
        height: 1.09rem;
        padding: 0 0.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d9d9d9;
        background-color: white;
        position: relative;
        cursor: pointer;
        .li-left {
          display: flex;
          align-items: center;
          img {
            width: 0.45rem;
            height: 0.45rem;
            display: inline-block;
            margin-right: 0.3rem;
          }
          .word {
            font-size: 0.32rem;
          }
        }
        .li-right {
          display: flex;
          font-size: 0.32rem;
          align-items: center;
        }
        .list-arrow {
            // position: absolute;
            // right: 0.3rem;
            // top: 50%;
            // transform: translateY(-50%);
            width: 0.39rem;
            height: 0.39rem;
            background-image: url('../../image/second/arrow1.png');
            background-size: 100% 100%;
            display: block;
          }
      }
  }
</style>
