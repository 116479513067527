<template>
    <div class="detail">
      <div class="top">
        <img class="headImg" :src="ask.avatar" v-show="ask.avatar"/>
        <img class="headImg" src="../../image/first/head.jpg" v-show="!ask.avatar"/>
        <div class="listInfo">
          <p class="name">{{ask.username}}</p>
          <p class="time">{{ask.gmtCreate}}</p>
        </div>
      </div>

      <div class="title">
        {{ask.problemTitle}}
      </div>
      <div class="cn">
        {{ask.problemContent}}
      </div>

      <div class="detailImg">
        <ImageShow class="detailShow" :src="ask.problemImage"></ImageShow>
        <!-- <img :src="ask.problemImage" /> -->
      </div>

      <div class="inline"></div>

      <div class="all">
        <p class="allAnswer">所有回复</p>
        <div class="allList">

          <div class="item" v-for="it in ask.problemReplyVOList" :key="it.id">
            <div class="top">
              <img class="headImg" :src="it.avatar" v-show="it.avatar"/>
              <img class="headImg" src="../../image/first/head.jpg" v-show="!it.avatar"/>
              <div class="listInfo">
                <p class="name">{{it.username}}</p>
                <p class="time">{{it.gmtCreate}}</p>
              </div>
            </div>
            <div class="item-b">
              <div class="ansBack">{{it.problemReplyContent}}</div>
              <!-- <img class="backImg" :src="it.problemReplyImage" /> -->
              <ImageShow class="backImg" :src="it.problemReplyImage"></ImageShow>
            </div>
          </div>

        </div>
      </div>

      <div class="button">
        <div class="btn" @click="askBack()">回复</div>
      </div>
    </div>
</template>

<script>
import ImageShow from '@/components/ImageShow';
export default {
  props: ['title'],
  components: {
    ImageShow
  },
  data () {
      return {
        id: 0,
        ask: {}
      }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getData(this.id);
  },
  methods: {
    getData() {
      this.$http.get(`/api/problem/getProblemInfo/${this.id}`, {
      })
      .then((res) => {
        this.ask = res.data.data;
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
    askBack() {
      this.$router.push(`/askBack?id=${this.id}`)
    }
  }

}
</script>

<style lang="less" scoped>
    .detail {
      padding-bottom: 2.5rem;
      .top {
        padding: 0 0.3rem;
        box-sizing: border-box;
        height: 1.2rem;
        display: flex;
        align-items: center;
        .headImg {
          width: 0.8rem;
          height: 0.8rem;
          border-radius: 0.8rem;
          margin-right: 0.3rem;
        }
        .name {
          font-size: 0.3rem;
        }
        .time {
          color: #999999;
        }
      }
      .title {
        padding: 0 0.3rem;
        box-sizing: border-box;
        font-size: 0.36rem;
        margin-top: 0.35rem;
      }
      .cn {
        padding: 0 0.3rem;
        box-sizing: border-box;
        font-size: 0.32rem;
        margin-top: 0.2rem;
      }
      .detailImg {
        padding: 0 0.3rem;
        box-sizing: border-box;
        margin-top: 0.35rem;
        margin-bottom: 0.35rem;
        .detailShow {
          width: 100%;
          height: auto;
          box-shadow: 0px 2px 7px 0px rgba(27, 27, 78, 0.1);
          border-radius: 0.2rem;
          overflow: hidden;
        }
        img {
          width: 100%;
          height: auto;
          box-shadow: 0px 2px 7px 0px rgba(27, 27, 78, 0.1);
        }
      }
      .inline {
        height: 0.2rem;
        background-color: #f4f6f6;
      }
      .all {
        padding: 0.3rem;
        box-sizing: border-box;
        .allAnswer {
          font-size: 0.3rem;
        }
        .item {
          margin-top: 0.2rem;
          padding-bottom: 0.2rem;
          border-bottom: 1px solid #f4f6f6;
          .item-b {
            padding: 0 0.3rem;
            box-sizing: border-box;
          }
          .ansBack {
            font-size: 0.32rem;
          }
          .backImg {
            width: 100%;
            height: auto;
            border-radius: 0.2rem;
            overflow: hidden;
            margin-top: 0.12rem;
          }
        }
      }
      .button {
        padding: 0 0.3rem;
        box-sizing: border-box;
        margin: 0.5rem auto 0;
        border-radius: 1rem;
        color: white;
        overflow: hidden;
        margin-bottom: 1rem;
        overflow: hidden;
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 7.5rem;
        .btn {
          background-color: #79cdca;
          width: 100%;
          height: 0.9rem;
          border-radius: 0.12rem;
          text-align: center;
          line-height: 0.9rem;
          font-size: 0.32rem;
          cursor: pointer;
        }
      }
    }
</style>
