<template>
    <div class="score">
      <Empty title="暂无商品" v-if="records.length === 0" />
      <div class="title">
        <div class="tl-a">
          <div class="tl-num">{{info.integral}}</div>
          <p>我的总积分</p>
        </div>
        <router-link to="/scoreRule" tag="div" class="tl-a">
          <img src="../../image/second/intrgal-1.png" />
          <p>积分规则</p>
        </router-link>

        <router-link to="/scoreHistory" tag="div" class="tl-a">
          <img src="../../image/second/intrgal-2.png" />
          <p>积分记录</p>
        </router-link>

      </div>
      <div class="list" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
        <div class="item" v-for="it in records" :key="it.id">
          <img :src="it.commodityImage" />
          <div class="cn">
            <p class="it-name">{{it.commodityName}}</p>
            <p class="it-num">所需积分: {{it.commodityIntegral}}</p>
            <div class="it-bo">
              <span class="bo-left">
                <span>数量:{{it.commodityNumber}}</span>
                <span class="hai">已兑换:{{it.commodityConvertedNumber}}</span>
              </span>
              <span class="bo-right" @click="recharge(it)">兑换</span>
            </div>
          </div>
        </div>
      </div>


      <!-- <div class="list">
        <div class="item">
          <img src="../../image/second/airplan.png" />
          <div class="cn">
            <p class="it-name">无人机</p>
            <p class="it-num">所需积分: 5000</p>
            <div class="it-bo">
              <span class="bo-left">
                <span>数量:100</span>
                <span class="hai">已兑换:0</span>
              </span>
              <span class="bo-right">兑换</span>
            </div>
          </div>
        </div>

        <div class="item">
          <img src="../../image/second/airplan.png" />
          <div class="cn">
            <p class="it-name">无人机</p>
            <p class="it-num">所需积分: 5000</p>
            <div class="it-bo">
              <span class="bo-left">
                <span>数量:100</span>
                <span class="hai">已兑换:999</span>
              </span>
              <span class="bo-right">兑换</span>
            </div>
          </div>
        </div>

        <div class="item">
          <img src="../../image/second/airplan.png" />
          <div class="cn">
            <p class="it-name">无人机</p>
            <p class="it-num">所需积分: 5000</p>
            <div class="it-bo">
              <span class="bo-left">
                <span>数量:100</span>
                <span class="hai">已兑换:99</span>
              </span>
              <span class="bo-right">兑换</span>
            </div>
          </div>
        </div>
        
      </div> -->
      <Tips :tip.sync="msg" />
    </div>
</template>

<script>
import { formatDate, baseUrl } from '../../common/util';
import Empty from '@/components/Empty';

export default {
  components: {
    Empty
  },
  data () {
      return {
        noData: false,
        records: [],
        busy: false,
        current: 1,
        size: 10,
        msg: '',
        info: {}
      }
  },
  mounted() {
    let info = localStorage.getItem('ng-data');
    if (info) {
      this.info = JSON.parse(info);
    }
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$http.get(`/api/user/getInfo`, {
      })
      .then((res) => {
          localStorage.setItem('ng-data', JSON.stringify(res.data.data));
          this.info = res.data.data;
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
    recharge(item) {
      this.$http.post(`/api/commodityExchangeRecord/exchangeCommodity/${item.id}`, {  
      })
      .then((res) => {
        this.info.integral = this.info.integral - item.commodityIntegral;
        this.msg = '兑换成功，等待管理员联系';
        localStorage.setItem('ng-data', JSON.stringify(this.info));
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
    loadMore() {
      this.$http.post('/api/commodity/pageList', {
        size: this.size,
        current: this.current,
      })
      .then((res) => {
        let data = res.data.data;
        if (this.records.length > 0 && data.records.length === 0) {
          this.busy = true;
          return;
        };
        this.records = this.records.concat(data.records);
        this.current ++;
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
  }
}
</script>

<style lang="less" scoped>
  .score {
    min-height: 100vh;
    background-color: #f4f6f6;
    .title {
      background-color: #79cdca;
      height: 1.8rem;
      display: flex;
      color: white;
      cursor: pointer;
      .tl-a {
        width: 33.33%;
        text-align: center;
        height: 100%;
        font-size: 0.28rem;
        padding-top: 0.3rem;
        box-sizing: border-box;
        .tl-num {
          font-size: 0.42rem;
          height: 0.8rem;
          line-height: 0.8rem;
          margin-bottom: 0.08rem;
        }
        img {
          width: 0.8rem;
          height: 0.8rem;
          display: block;
          margin: 0 auto;
          margin-bottom: 0.08rem;
        }
      }
    }
    .list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 0.2rem;
      padding: 0 0.3rem 0.5rem;
      box-sizing: border-box;
      overflow: scroll;
      max-height: calc(100vh - 1.8rem);
      .item {
        background: white;
        color: #999999;
        width: 3.35rem;
        box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.03);
        border-radius: 0.12rem;
        overflow: hidden;
        margin-bottom: 0.2rem;
        cursor: pointer;
        img {
          width: 100%;
          height: 2.79rem;
        }
        .cn {
          padding: 0 0.15rem 0.2rem;
          box-sizing: border-box;
        }
        .it-name {
          font-size: 0.28rem;
          color: #333333;
        }
        .it-num {
          font-size: 0.24rem;
          margin-top: 0.05rem;
        }
        .it-bo {
          font-size: 0.24rem;
          margin: 0 auto 0.05rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .bo-left {
            .hai {
              margin-left: 0.05rem;
            }
          }
          .bo-right {
            background-color: #79cdca;
            color: white;
            padding: 0.05rem 0.15rem;
            border-radius: 0.2rem;
          }
        }
      }
    }
  }
</style>
