<template>
    <div class="about">
      <div class="top">
        <img class="icon" src="../../image/second/icon.png" />
        <p class="name">明九章</p>
        <p class="version">Version 1.0.5</p>
      </div>
      
      <router-link class="my-list" to="/agreeMent" tag="div">
        <span class="li-left">
          <span class="word">用户协议</span>
        </span>
        <i class="list-arrow" />
      </router-link>

      <div class="my-list" @click="jump">
        <span class="li-left">
          <span class="word">联系我们</span>
        </span>
        <i class="list-arrow" />
      </div>

      <router-link class="my-list" to="/appdownLoad" tag="div">
        <span class="li-left">
          <span class="word">下载App</span>
        </span>
        <i class="list-arrow" />
      </router-link>
    </div>
</template>

<script>
import { formatDate, baseUrl } from '../../common/util';

export default {
  components: {
    
  },
  data () {
    return {
      active: 0,
    }
  },
  mounted() {
    
  },
  methods: {
    jump() {
      window.location.href = 'https://map.baidu.com/mobile/webapp/place/detail/qt=inf&uid=42bf8f0a1ed6cbfbbca822f3&act=read_share'
    }
  }

} 
</script>

<style lang="less" scoped>
  .about {
    min-height: 100vh;
    background-color: #f4f6f6;
    .top {
      padding-top: 0.5rem;
      text-align: center;
      .icon {
        width: 1.2rem;
        height: 1.2rem;
        display: block;
        margin: 0 auto;
        border-radius: 0.2rem;
        box-shadow: 0px 2px 7px 0px rgba(27, 27, 78, 0.1);
      }
      .name {
        color: #333333;
        font-size: 0.35rem;
        margin: 0.1rem auto 0;
      }
      .version {
        font-size: 0.28rem;
        color: #cccccc;
        margin-bottom: 0.1rem;
      }
    }
    .my-list {
      &.space {
        margin-top: 0.2rem;
      }
      height: 1.09rem;
      padding: 0 0.3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #d9d9d9;
      background-color: white;
      position: relative;
      .li-left {
        display: flex;
        align-items: center;
        img {
          width: 0.45rem;
          height: 0.45rem;
          display: inline-block;
          margin-right: 0.3rem;
        }
        .word {
          font-size: 0.32rem;
        }
      }
      .list-arrow {
          // position: absolute;
          // right: 0.3rem;
          // top: 50%;
          // transform: translateY(-50%);
          width: 0.39rem;
          height: 0.39rem;
          background-image: url('../../image/second/arrow1.png');
          background-size: 100% 100%;
          display: block;
        }
    }
  }
</style>
