<template>
    <transition name="fold">
        <div class="dialog">
          <div class="head">
            题目解析
            <span class="right" @click="close">
                关闭
            </span>
          </div>
          <div class="cn">
              <p class="answer">【答案】 {{ info.answer | tranAn }}</p>
              <p class="answer">【解析】 {{ info.answerContent | tranCn }}</p>
              <ImageShow :src="info.answerImage" class="tiImg" v-show="info.answerImage" />  
          </div>
        </div> 
    </transition>
</template>

<script>
import ImageShow from '@/components/ImageShow';
export default {
  props: ['info'],
  components: {
    ImageShow
  },
  data () {
      return {
        active: 0,
      }
  },
  filters: {
    tranCn: function (value) {
        if (!value) return '略略'
        value = value.toString()
        return value.substring(0, 10)
    },
    tranAn: function (value) {
        if (!value) return '略略'
        value = value.join('，')
        return value
    }
  },
  mounted() {
    // console.log(this.props);
  },
  methods: {
    close() {
        this.$parent.look = false;
    }
  }

}
</script>

<style lang="less" scoped>
    .dialog {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: white;
        z-index: 10;
        .head {
          height: 1rem;
          border-bottom: 1px solid  #d9d9d9;
          position: relative;
          line-height: 1rem;
          text-align: center;
          font-size: 0.36rem;
          position: relative;
          .right {
            position: absolute;
            right: 0.3rem;
            top: 50%;
            transform: translateY(-50%);
            font-size: 0,.36rem;
            color: #999;
          }
        }
        .cn {
            padding-bottom: 0.6rem;
            .answer {
                font-size: 0.36rem;
                color: black;
                margin: 0.1rem auto;
                padding-left: 0.3rem;
            }
            .tiImg {
                box-sizing: border-box;
                box-shadow: 0px 2px 7px 0px rgba(27, 27, 78, 0.1);
                border-radius: 0.15rem;
                width: 90%;
                height: auto;
                display: block;
                margin: 0.2rem auto 0;
                overflow: hidden;
            }
        }
    }
</style>
