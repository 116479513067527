<template>
    <div class="make">
        <div class="make1">
            <div class="title">
                <span class="num">
                    第{{curr + 1}}/{{list.length}}题
                </span>
                <span class="name">{{ title }}</span>
            </div>
            <div class="cn">
                {{info.title}}
            </div>
            <div class="cn1">
                <div class="cnImg" v-show="info.titleImage">
                    <ImageShow :src="info.titleImage" class="tiImg" />    
                </div>
            </div>
        </div>

        <div class="tiankong" v-show="info.type == 1">
           <div class="intro">
               <span>选择：</span>
               <span class="look" @click="look = true">查看解析</span>
           </div>
           <div class="tian-btn"
                v-for="(it, index) in info.requestOption"
                :key="index"
                :class="info.answer[0] == it && 'active'"
            >
               {{selectList[index]}}选项： {{it}}
           </div>
        </div>

       <div class="tiankong" v-show="info.type == 2">
           <div class="intro">
               <span>填空：</span>
               <span class="look"  @click="look = true">查看解析</span>
           </div>
           <div class="tian-input" 
                v-for="(it, index) in info.answer"
                :key="index"
            >
               <input :placeholder="`第${index + 1}空`" v-model="info.answer[index]" disabled/>
           </div>
           <!-- <div class="tian-input">
               <input placeholder="第2空" />
           </div> -->
       </div>

        <div class="clickBtn">
            <div class="left">
                <div class="one" v-show="curr == 0" @click="nextTi">
                    下一题
                </div>
                <div class="two" v-show="curr >= 1">
                    <p class="last" @click="lastTi">上一题</p>
                    <p class="next" @click="nextTi" v-show="curr + 1 < list.length">下一题</p>
                    <p class="next" @click="comTi" v-show="curr + 1 == list.length">返回上一页</p>
                </div>
            </div>
            <div class="right">
                <div class="r-btn" @click="showList = true">
                    <img src="../../image/task/caidan.png" />
                    <span>菜单</span>
                </div>
            </div>
        </div>

        <div>
            <div class="bg" v-show="look" />
            <LookDetail  :info="info" v-show="look" />
        </div>
        
        <div>
            <div class="bg" v-show="showList" @click="showList = false"/>
            <transition name="task">
                <div class="rigthDialog" v-show="showList">
                    <div class="list">
                        <div class="item" 
                            v-for="(it, index) in list" 
                            :key="it.id"
                            :class="index == curr && 'active'"
                            @click="chooseTi(index)"
                        >
                            <span>第{{index + 1}}题</span>
                            <span>
                                <span class="result error" v-show="it.isCorrect === 0">错误</span>
                                <span class="result" v-show="it.isCorrect === 1">正确</span>
                            </span>
                        </div>
                    </div>
                    <div class="bottom">
                        <p class="time">总分：{{score}}分</p>
                        <p class="time">用时：{{ count | timeMin}}</p>
                    </div>
                </div>
            </transition>
        </div>
        <Tips :tip="msg" />
    </div>
</template>

<script>
import LookDetail from '@/components/LookDetail';
import ImageShow from '@/components/ImageShow';
import { getQuery } from '../../common/util';
export default {
    components: {
        LookDetail,
        ImageShow
    },
    filters: {
        timeMin: function (value) {
            if (value < 60) {
                return `0分${value}秒`
            }
            if (value >= 60 && value < 3600) {
                let min = Math.floor(value / 60);
                let sec = value - min * 60;
                return `${min}分${sec}秒`
            }
            if (value >= 3600) {
                let hour =  Math.floor(value / 3600);
                let min = Math.floor((value - 3600 * hour) / 60);
                let sec = value - hour * 3600 - min * 60;
                return `${hour}小时${min}分${sec}秒`
            }
        }
    },
    data () {
        return {
            look: false,
            courseName: '',
            info: {},
            curr: 0,
            list: [],
            showList: false,
            select: -1,
            selectList: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
            info: {
                activityId: 1,
                answer: "",
                answerContent: "",
                answerImage: "",
                id: '',
                integral: '',       
                requestOption: [],
                title: "",
                titleImage: "",
                select: -1,
                type: 1, // 1-选则题， 2-填空题
                tianList: []
            },
            // 填空
            tianList: [],
            msg: '',
            count: 0,
            title: '',
            isCorrect: 1,
            answerId: '',
            answerList: [1, 1, 0, 1, 0],
            type: '',
            score: ''
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        this.score = getQuery('score');
        this.getData();
    },
    methods: {
        timeMin1(value) {
            if (value < 60) {
                return `0分${value}秒`
            }
            if (value >= 60 && value < 3600) {
                let min = Math.floor(value / 60);
                let sec = value - min * 60;
                return `${min}分${sec}秒`
            }
            if (value >= 3600) {
                let hour =  Math.floor(value / 3600);
                let min = Math.floor((value - 3600 * hour) / 60);
                let sec = value - hour * 3600 - min * 60;
                return `${hour}小时${min}分${sec}秒`
            }
        },
        lastTi() {
            if (this.curr == 0) {
                return;
            }
            this.curr = this.curr - 1;
            this.chooseTi(this.curr);
        },
        nextTi() {
            if (this.curr + 1 == this.list.length) {
                return;
            }
            this.curr = this.curr + 1;
            this.chooseTi(this.curr);
        },
        comTi() {
            this.$router.replace('/main/test');
        },
        stringToOb(ob) {
            if (typeof ob === 'string') {
                return JSON.parse(ob)
            }
            return ob
        },
        chooseTi(index) {
            this.curr = index;
            let info =  this.list[index];
            info.requestOption = this.stringToOb(info.requestOption);
            info.answer = this.stringToOb(info.answer);
            this.info = info;
            this.info.tianList = info?.tianList ?  info?.tianList : [];
            this.courseName = info.subjectDictName + '-' + info.subjectFirstDictName + '-' +info.subjectCateName
        },
        getData() {
            this.$http.get(`/api/answerLog/list/${this.id}`, {
            })
            .then((res) => {
                this.list = res.data.data.answerLogVOList;
                const subjectDictName = res.data.data.subjectDictName;
                const subjectFirstDictName = res.data.data.subjectFirstDictName;
                const subjectSecondDictName = res.data.data.subjectSecondDictName;
                const subjectCateName = res.data.data.subjectCateName;
                this.title = subjectDictName + '-' + subjectFirstDictName + '-' +subjectSecondDictName + '-' +subjectCateName;
                this.count = res.data.data.answerDuration;
                this.chooseTi(0);
            }).catch((err) => {
                this.validErrorInfo = err.errorMsg;
            })
        },
    },
}
</script>

<style lang="less" scoped>
    .make {
        min-height: 100vh;
        background-color: #f4f6f6;
        position: relative;
        z-index: 0;
        .make1 {
            background-color: white;
            padding-bottom: 0.35rem;
        }
        .title {
            padding: 0 0.3rem;
            box-sizing: border-box;
            height: 0.8rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: white;
            .num {
                font-size: 0.28rem;
                background-color: #79cdca;
                padding: 0.05rem 0.15rem;
                color: white;
                border-radius: 0.12rem;
            }
            .name {
                font-size: 0.25rem;
                color: #666666;
            }
        }
        .cn {
            padding: 0.2rem 0.3rem 0;
            box-sizing: border-box;
            font-size: 0.35rem;
            color: #333333;
        }
        .cn1 {
            padding: 0 0.3rem;
            box-sizing: border-box;
            margin-top: 0.35rem;
            .cnImg {
                padding: 0.3rem;
                box-sizing: border-box;
                box-shadow: 0px 2px 7px 0px rgba(27, 27, 78, 0.1);
                border-radius: 0.15rem;
                .tiImg {
                    display: block;
                    margin: 0 auto;
                    width: 100%;
                    height: auto;
                }
            }
        }
        .tiankong {
            margin-top: 0.35rem;
            padding: 0 0.3rem;
            box-sizing: border-box;
            padding-bottom: 2.6rem;
            .intro {
                display: flex;
                justify-content: space-between;
                font-size: 0.28rem;
                color: #999;
                .look {
                    font-size: 0.32rem;
                }
            }
            .tian-btn {
                &.active {
                    background-color: #79cdca;
                    color: white;
                }
                width: 4.5rem;
                height: 1rem;
                line-height: 1rem;
                background-color: #e1f0f0;
                display: block;
                border-radius: 1rem;
                margin: 0.45rem auto 0;
                text-align: center;
                font-size: 0.35rem;
                color: #999;
            }
            .tian-input {
                width: 4.5rem;
                height: 1rem;
                background-color: #e1f0f0;
                display: block;
                border-radius: 1rem;
                margin: 0.45rem auto 0;
                display: flex;
                align-items: center;
                overflow: hidden;
                input {
                    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                        text-align: center;
                    }
                    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                        text-align: center;
                    }
                    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                        text-align: center;
                    }
                    background-color:#e1f0f0;
                    height: 0.85rem;
                    width: 95%;
                    display: block;
                    font-size: 0.35rem;
                    text-align: center;
                }
            }
        }
        .clickBtn {
            position: absolute;
            left: 0;
            // left: 50%;
            // transform: translateX(-50%);
            bottom: 0.5rem;
            padding: 0 0.3rem;
            box-sizing: border-box;
            width: 100%;
            max-width: 7.5rem;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .left {
                .one {
                    width: 5rem;
                    height: 0.8rem;
                    line-height: 0.8rem;
                    border-radius: 1rem;
                    background-color: #79cdca;
                    text-align: center;
                    font-size: 0.36rem;
                    color: white;
                }
                .two {
                    text-align: center;
                    font-size: 0.36rem;
                    width: 5rem;
                    .last {
                        width: 1.5rem;
                        height: 0.8rem;
                        line-height: 0.8rem;
                        border-radius: 1rem;
                        background-color: #bebebe;
                        color: white;
                        display: inline-block;
                        margin-right: 0.3rem;
                    }
                    .next {
                        width: 3.2rem;
                        height: 0.8rem;
                        line-height: 0.8rem;
                        border-radius: 1rem;
                        background-color: #79cdca;
                        color: white;
                        display: inline-block;
                    }
                }
            }
            .right {
                width: 1.5rem;
                height: 0.8rem;
                background-color: #9cdad6;
                border: 1px solid #79cdca;
                border-radius: 0.12rem;
                .r-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    font-size: 0.32rem;
                    margin-top: 0.08rem;
                    img {
                        width: 0.6rem;
                        height: 0.6rem;
                        margin-right: 0.06rem;
                    }
                }
            }
        }
        .rigthDialog {
            position: absolute;
            top: 0;
            height: 100%;
            right: 0;
            width: 3rem;
            background-color: #f4f6f6;
            z-index: 10;
            .list {
                height: 10rem;
                overflow-y: scroll;
                .item {
                    &.active {
                        background-color:  #e1f0f0;
                    }
                    height: 1rem;
                    border-bottom: 1px solid #d9d9d9;
                    padding: 0 0.3rem;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 0.32rem;
                    color: #333333;
                    .result {
                        &.error {
                            color: #CA0E2D;
                        }
                        color:  #79cdca;
                    }
                }
            }
            .bottom {
                padding: 0 0.3rem;
                box-sizing: border-box;
                .time {
                    font-size: 0.3rem;
                    margin-top: 0.25rem;
                }
                .againBtn {
                    margin-top: 0.25rem;
                    height: 0.8rem;
                    line-height: 0.8rem;
                    text-align: center;
                    width: 100%;
                    border-radius: 1rem;
                    font-size: 0.32rem;
                    color: white;
                    background-image: linear-gradient(to right, #f0bf5a, #e8343a);
                }
                .backBtn {
                    margin-top: 0.25rem;
                    background-color: #79cdca;
                    height: 0.8rem;
                    line-height: 0.8rem;
                    text-align: center;
                    width: 100%;
                    border-radius: 1rem;
                    font-size: 0.32rem;
                    color: white;
                }
            }
        }
    }
</style>
