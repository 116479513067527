<template>
    <div class="empty">
       {{ title }}
    </div>
</template>

<script>

export default {
  props: ['title'],
  components: {
    
  },
  data () {
      return {
        active: 0,
      }
  },
  mounted() {
    // console.log(this.props);
  },
  methods: {
    
  }

}
</script>

<style lang="less" scoped>
    .empty {
        position: absolute;
        top: 33.333%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 0.42rem;
        color: #cccccc;
    }
</style>
