<template>
    <div class="answer">
      <Empty title="暂无拼团" v-if="records.length === 0" />
      <div class="loadMore" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
        <div class="item" 
          v-for="(it) in records"
          :key="it.id"
        >
          <div class="item-c" @click="jump(it.courseSeriesId)">
            <img src="../../image/course/ping.png">
            <div class="item-right">
              <p>{{it.courseSeriesName}}</p>
              <p class="item-p">{{it.courseCateName}}</p>
            </div>
            <span class="status" v-show="it.groupStatus == 0">待分享，差{{it.missGroupCount}}人</span>
            <span class="status" v-show="it.groupStatus == 1">拼团成功</span>
            <span class="status" v-show="it.groupStatus == 2">拼团失败</span>
          </div>
          <p class="bootom">
            <span>订单时间：{{it.gmtCreate}}</span>
            <span class="money">实付：¥{{it.realPrice}}</span>
          </p>
          <p class="btns">
            <span></span> 
            <span class="btn" v-show="it.groupStatus != 1" @click="share(it.courseSeriesId, $event)">邀请好友拼单</span>
            <span class="btn" v-show="it.groupStatus == 1" @click="share(it.courseSeriesId, $event)">分享给好友</span>
          </p>
        </div>
      </div>

      <Tips :tip="msg" /> 
    </div>
</template>

<script>
import { formatDate, baseUrl, copyToDoc } from '../../common/util';
import Empty from '@/components/Empty';

export default {
  components: {
    Empty
  },
  data () {
      return {
        records: [],
        busy: false,
        current: 1,
        size: 10,
        active: 0,
        msg: ''
      }
  },
  mounted() {
    
  },
  methods: {
    jump(id) {
      this.$router.push(`/courseList/${id}`)
    },
    share(id, $event) {
      const href = `${window.location.origin}/#/courseList/${id}`;
      copyToDoc(href, () => {
        this.msg = '复制成功';
      })
      $event.preventDefault();
      $event.stopPropagation();
    },
    loadMore() {
      this.$http.post('/api/groupOrder/pageList', {
        size: this.size,
        current: this.current,
      })
      .then((res) => {
        let data = res.data.data;
        if (this.records.length > 0 && data.records.length === 0) {
          this.busy = true;
          return;
        };
        this.records = this.records.concat(data.records);
        this.current ++;
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
  }
}
</script>

<style lang="less" scoped>
  .answer {
    min-height: 100vh;
    background-color: #f4f6f6;
    .loadMore {
      min-height: 100vh;
    }
    .item {
      background-color: white;
      padding: 0 0.3rem;
      box-sizing: border-box;
      margin-bottom: 0.3rem;
      .item-c {
        display: flex;
        align-items: center;
        height: 1.5rem;
        border-bottom: 1px solid #eeeeee;
        position: relative;
        img {
          width: 1rem;
          height: 1rem;
        }
        .item-right {
          margin-left: 0.3rem;
          font-size: 0.28rem;
          color: #333333;
        }
        .item-p {
          height: 0.42rem;
          line-height: 0.42rem;
        }
        .status {
          position: absolute;
          top: 0.35rem;
          right: 0;
          font-size: 0.26rem;
          color: #cf483a;
        }
      }
      .bootom {
        height: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.26rem;
        border-bottom: 1px solid #eeeeee;
        .money {
          // font-weight: bold;
          font-size: 0.28rem;
        }
      }
      .btns {
        display: flex;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn {
          background-color:  #cf483a;
          padding: 0.1rem 0.2rem;
          color: white;
          border-radius: 0.3rem;
        }
      }

    }
  }
</style>
