import Loading from './loading/loading.vue';

import Alert from './alert/alert.vue';

import Tips from './tips/tips.vue';

import Confirm from './confirm/confirm.vue';

const BaseCom = {
    install: function(Vue) {
        Vue.component('Loading', Loading);
        Vue.component('Alert', Alert);
        Vue.component('Tips', Tips);
        Vue.component('Confirm', Confirm);
    }
}

export default BaseCom;