<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  import {mapGetters, mapActions,mapState } from 'vuex'
  export default {
    name: 'App',
    data () {
      return {
        loginDialog:false
      }
    },
    components: {

    },
    computed:{
      ...mapState({
        showDialog: state => state.showDialog,
        netWorkError: state => state.netWorkError,
        netWorkCode: state => state.netWorkCode
      })
    },
    beforeCreate() {
      
    },
    mounted() {
       // document.title = '个人信息';
    },
    methods: {
      closeButton(){
        this.showDialog1(false);
      },
      ...mapActions({
        showDialog1: 'showDialog',
        netWorkError1: 'netWorkError',
        netWorkCode1:'netWorkCode'
      })
    },
    watch:{
      showDialog: function (curr) {
        if (curr) {

          alert(this.netWorkError, () => {
              if(this.netWorkCode == 408){
                  this.netWorkCode1('');
                  localStorage.removeItem('ng-token');
                  localStorage.removeItem('ng-data');
                  this.$router.push('/login');
              }
            }
          )
          this.showDialog1(false)
        }
      }
    }
  }
</script>
<style lang="less">
#app {
  width: 7.5rem;
  height: 100%;
  margin: 0 auto;
  // background-color: #f4f6f6;
}
</style>
