<template>
    <div class="loading">
        <div class="loadImg" />
        <div class="tips">{{msg}}</div>
    </div>    
</template>
<script>
export default {
    props: ['msg'],
    data() {
        return {
            msg:'loading......'
        }
    }
}
</script>

<style scoped lang="less">
    .loading {
        position: fixed;
        background-color: #3a3a3a;
        opacity: .9;
        border-radius: .06rem;
        min-height: .72rem;
        top: 50vh;
        left: 50%;
        transform: translateX(-50%);
        width: 2rem;
        height: 2rem;
        .loadImg {
            background-image: url('../../../image/gif/loading.gif');
            background-size: 2.5rem 2rem;
            background-position: center center;
            width: 1.2rem;
            height: 1.2rem;
            margin: 0.2rem auto 0;
            display: block;
        }
        .tips {
            text-align: center;
            color: white;
        }
    }
</style>