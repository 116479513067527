<template>
    <div class="class">
        <!-- <SecMenu /> -->
        <div
            class="item"
            @click="makeAnswer(it)"
            v-for="it in list"
            :key="it.id"
        >
            <div class="my-list">
                <span class="li-left">
                <span class="word">{{it.subjectCateName}}</span>
                </span>
                <i class="list-arrow" />
            </div>
        </div>
    </div>
</template>

<script>

import SecMenu from '@/components/SecMenu';
export default {
    props: ['title'],
    components: {
        SecMenu
    },
    data () {
        return {
            active: 0,
            id: '',
            list: []
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        this.getData();
    },
    methods: {
        getData() {
            this.$http.get(`/api/subjectCate/list/${this.id}`, {
            })
            .then((res) => {
                this.list = res.data.data;
            }).catch((err) => {
                this.validErrorInfo = err.errorMsg;
            })
        },
        makeAnswer(it) {
            this.$router.push(`/classMake/${it.id}?isTest=${it?.isTest || 0 }`);
        }
    }
}
</script>

<style lang="less" scoped>
    .class {
        .my-list {
            &.space {
                background-color: #d9d9d9;
            }
            text-decoration: none;
            height: 1.09rem;
            padding: 0 0.3rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #d9d9d9;
            background-color: #f7f7f7;
            position: relative;
            color: #333333;
            .li-left {
            display: flex;
            align-items: center;
            img {
                width: 0.45rem;
                height: 0.45rem;
                display: inline-block;
                margin-right: 0.3rem;
            }
            .word {
                font-size: 0.32rem;
            }
            }
            .list-arrow {
                // position: absolute;
                // right: 0.3rem;
                // top: 50%;
                // transform: translateY(-50%);
                width: 0.39rem;
                height: 0.39rem;
                background-image: url('../../../image/second/arrow1.png');
                background-size: 100% 100%;
                display: block;
            }
        }
        .second {
            padding-left: 0.3rem;
            box-sizing: border-box;
        }
    }
</style>
