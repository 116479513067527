<template>
    <div v-show="show">
        <div class="nbaMask"></div>
        <div class="nbaDialog">
            <div class="title">
                {{tip}}
            </div>
            <div class="btns">
                <div class="cancel" @click="exit">
                    取消
                </div>
                <div class="sure" @click="sure">
                    确定
                </div>
            </div>
        </div>
    </div>  
</template>
<script>
export default {
    props: ['tip', 'callback', 'cancel'],
    data() {
        return {
            show: false
        }
    },
    mounted() {
        if (this.tip != '') {
            this.show = true;
        }
    },
    methods: {
        sure () {
            this.$emit('update:tip', '');
            this.callback && this.callback();
        },
        exit() {
            this.show = false;
            this.$emit('update:tip', '');
            this.cancel && this.cancel();
        },
        empty() {
            this.$emit('update:tip', '');
        }
    },
    watch: {
        tip(val, s) {
            if (val != '') {
                this.show = true;
            } else {
                this.show = false;
            }
        }
    }
}
</script>

<style scoped lang="less">
    .nbaMask { 
        position: fixed;
        z-index: 1000;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
    }
    .nbaDialog {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-width: 5.6rem;
        border-radius: .2rem;
        background-color: #fff;
        overflow: hidden;
        z-index: 1001;
        .title {
            font-size: .36rem;
            max-height: 70vh;
            overflow-y: scroll;
            border-radius: 0;
            word-break: break-all;
            font-size: .3rem;
            padding: 0.4rem;
            text-align: center;
            color: #666;
        }
        .btns {
            display: flex;
            border-top: 1px solid #eee;
            width: 100%;
            min-height: 1.1rem;
            color: #333;
            background: #fff;
            margin: 0 auto;
            font-size: .36rem;
            text-align: center;
            color: #666;
            .cancel {
                flex: 1;
                line-height: 1.1rem;
            }
            .sure {
                flex: 1;
                color: #ab3b3a;
                border-left: 1px solid #eee;
                line-height: 1.1rem;
            }
        }

    }
</style>