import axios from 'axios'
import store from '../store'
import qs from 'qs'
axios.defaults.timeout = 500000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.withCredentials = false

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = '/apl'
} else {
  axios.defaults.baseURL = 'http://mingjiuzhang.cn'
}

// axios.defaults.baseURL = 'http://42.192.90.32:9094/'

// axios.defaults.responseType = 'json';

axios.interceptors.request.use((config) => {
  // if(config.method  === 'post'){
  //   config.data = qs.stringify(config.data);
  // }
  if (localStorage.getItem('ng-token')) {
    const token = localStorage.getItem('ng-token')
    config.headers['Authorization'] = token;
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

axios.interceptors.response.use((res) => {
  if (res.data.code != 200) {
    store.dispatch('showDialog', true)
    store.dispatch('netWorkCode', res.data.code)
    store.dispatch('netWorkError', res.data.message)
    return Promise.reject(res.data)
  }
  return res
}, (error) => {
  return Promise.reject(error)
})

export default axios
