<template>
    <div>
       <div v-show="show" @click="showQr">
           <div class="bg bg1" />
           <div class="large">
                <vue-qr 
                    class="qrcode"
                    :logoSrc="logo" 
                    :text="url"
                    :size="260"
                />
                <p class="tips">购买成功以后请手动刷新页面</p>
                <p class="tips pin">拼团购买需要等待拼团成功</p>
           </div>
       </div>
    </div>
</template>

<script>
import vueQr from 'vue-qr'
export default {
  props: ['show', 'url'],
  components: {
    vueQr
  },
  data () {
      return {
        logo: require("../image/second/icon.png"),
      }
  },
  mounted() {

  },
  methods: {
      showQr() {
        this.$emit('update:show', !this.show);
      }
  }

}
</script>

<style lang="less" scoped>
    .fix {
        width: 100%;
        height: 100%;
    }
    .bg1 {
        background-color: rgba(0,0,0, 1);
    }
    .large {
        position: fixed;
        width: 100%;
        max-width: 7.5rem;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        .qrcode {
            margin: 0 auto;
            display: block !important;
        }
        .tips {
            &.pin {
                margin-top: 0.1rem;
            }
            margin: 0.6rem auto 0;
            font-size: 0.32rem;
            color: white;
            text-align: center;
        }
    }
</style>
