<template>
    <div class="agreeMent">
       <p class="bold">
          本用户支付协议(以下简称“本协议”)由您与江山市明九章文化传播工作室(以下简称“明九章”)共同签署,在您使用“明九章”软件服务平台并开通用户支付服务之前,请仔细阅读本协议(无民事行为能力人、限制民事行为能力人应在法定监护人陪同下阅读)。
          一旦您使用明九章软件服务平台且在付款页面点击“确认支付”按钮,即表示您已经仔细阅读并同意完全接受本协议所有条款和所有条件的约束。您接受本协议的全部条款及条件,且点击“确认支付”后,您将从明九章软件注册用户成为明九章软件服务平台的充值用户。
          您同意:明九章有权随时修改本协议,并以在明九章软件服务平台公示的方式通知您;修改后的协议自公示之日生效。本协议的条款修改后,如果您不同意本协议条款的修改,可以取消并停止继续使用明九章软件服务;如果您继续使用明九章软件服务,则视为您已经接受本协议的全部修改内容。
       </p>
        <p class="title">
          1.充值用户服务说明
        </p>
        <p>
          1.1明九章在向您提供用户服务时,所涉及的明九章软件产品及内含信息(包括但不限于:文本、图片、标识、音频、视频资料)均受著作权、商标权及其他财产权的法律保护;未经明九章及相关权利人的书面授权,您不得将上述资料以任何方式展示于第三方平台或被用于其他商业目的。未经明九章书面同意,不得为任何营利性或非营利性的目的自行实施、利用、转让或允许任何第三方实施、利用、转让上述知识产权及相关权利。
        </p>
        <p>
          1.2明九章享有对您在使用明九章软件服务平台过程中的一切活动的监督,提示,检查的权利,如果您的行为违反有关法律法规或违反本协议条款的约定,明九章有要求您纠正您的行为,并保留追究责任等相关权利。
        </p>
        <p >
          1.3明九章为您提供的全部产品服务,均仅限于您在明九章软件服务平台处使用,任何以恶意破解等非法手段将明九章软件服务平台提供的产品服务内容与明九章软件服务平台分离的行为,由此引起的一切法律后果由行为人自行承担,造成明九章损失的,明九章有权依法追究行为人的法律责任。
        </p>
        <p>
          1.4您应自行负责妥善、正确地保管、使用、维护您在明九章申请注册时取得的用户账户、账户信息及账户密码。您应对其账户信息、账户密码采取必要和有效的保密措施,非明九章原因致使您的账户密码泄露以及因您的保管、使用、维护账户信息及软件服务平台终端设备不当造成的损失,明九章无需承担与此有关的任何责任。
        </p>
        <p>
          1.5您可以通过登录您的明九章账号免费查询您的用户账号信息详情,包括但不限于账户剩余量情况、交易订单信息等。
        </p>
        <p>
          1.6您已充分明确了解并同意,明九章用户充值的付费方式为:微信支付、支付宝支付,您明确理解并知晓通过此种付费方式付费可能存在一定的商业风险,包括但不限于不法分子利用您的账户或银行卡等有价卡进行违法活动,该等风险均会给您造成相应的经济损失。您承诺自行承担向侵权方追究侵权责任和追究责任不能的后果。
        </p>
        <p>
          1.7明九章不对您因第三方的行为或不作为造成的损失承担任何责任,包括但不限于支付服务和网络接入服务,任何第三方的侵权行为等。
        </p>
        <p>
          1.8明九章提供的明九章软件服务平台中可能包括广告,用户同意在使用过程中显示明九章和第三方供应商、合作伙伴提供的广告。
        </p>
        <p class="title">
          2.充值用户账户
        </p>
        <p>
          2.1充值用户信息:在您成为明九章用户之前,您必须首先根据真实、准确、有效的个人信息注册成为明九章用户,您所填写的注册明九章用户的内容与个人资料必须真实、有效,否则明九章有权拒绝您的申请或撤销您的用户资格,并不给予任何赔偿。您通过第三方支付平台进行充值而成为明九章充值用户之前,应确保您进行充值所使用的账户信息正确、正式、有效,否则明九章不予负责由此导致的损失及不予退还由此误充值而导致的增加余额。您申请注册的个人资料在注册后发生变更,应在第一时间及时通知明九章协助变更您注册的个人资料。否则在您提出变更信息前,由此造成的您应享有的用户权利不能全面有效行使的责任或者由此导致的用户权益的损失由您自行承担。明九章如发现有非正常操作行为,有权因此暂停或者终止您的充值用户资格,并不退还您已充值的账户余额或对您作出其他任何形式的赔偿/补偿。
        </p>
        <p>
          2.2您可以通过您已有的充值用户账户进行后续的反复多次充值续费。您不得在充值用户账户余额不足时支付服务订单费用,不得因账户余额向明九章主张利息及其他费用。
        </p>
        <p>
          2.3鉴于明九章充值用户唯一的身份验证是您充值时所使用的用户账户,凡登录用户账户所进行的操作均视为您本人的行为。
        </p>
        <p>
          2.4明九章仅提供相关的软件服务及订单服务,除此之外与相关软件服务有关的设备(如个人电脑、手机、及其他与接入互联网或移动网有关的装置)及所需的费用(如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费)、第三方支付方式手续费等均应由您自行负担。
        </p>
        <p class="title">
          3.充值用户服务的使用、变更、退出
        </p>
        <p>
          3.1您可以通过登录明九章充值用户账户,使用明九章服务平台提供的产品服务,包括但不限于:拍照批改作业等,并通过您账户内的余额及明九章不定期发布的优惠政
        </p>
        <p>
          3.2您成为明九章充值用户,即视为您认可明九章软件服务平台所公示的各项产品服务标明的服务价格、产品服务内容、计价方式、结算方式等。明九章将在对应服务人员为您提供服务后将对应的服务金额从您的账户中予以扣除,并代您向对应的服务人员支付服务费用
        </p>
        <p>
          3.3明九章充值用户的优惠服务标准和优惠政策以明九章软件平台上标注的详细资费标价及优惠政策内容为准,明九章有权根据业务发展情况制定、调整、修改、终止作废所推出的优惠政策的条件、有效期、政策内容等。
        </p>
        <p>
          3.4充值用户权利的限制:
        </p>
        <p>
           3.4.1若明九章充值用户有以下行为之一的,明九章教育有权取消该用户的服务资格,由此引发的问题及损失均应由用户独立承担:
        </p>
        <p>
           3.4.1.1用户的行为持续违反本协议或违反国家相关法律法规,或明九章认为用户行为有损明九章或他人的声誉或利益的;
        </p>
        <p >
          3.4.1.2用户以盗窃、利用软件系统漏洞等非法途径获取或购买明九章账户充值金额,或获取明九章服务平台提供的产品服务的;
        </p>
        <p>
          3.4.1.3用户对订单服务人员作出带有非法淫秽、侮辱或带有人身攻击含义的表示或带有人身攻击的评价的;
        </p>
        <p>
          3.5用户在明九章软件服务平台使用明九章产品服务的过程中,如果遇到服务纠纷或在使用服务过程中有任何故障或者疑问,可向明九章进行投诉或咨询。具体的服务异议提出流程以明九章软件服务平台公示规则为准,您已清楚了解并知悉,您应在明九章软件服务平台公示的时间范围内提出服务异议,否则明九章将难以对您的异议作出有效的处理。
        </p>
        <p>
          3.6充值用户账户的退出:
        </p>
        <p>
          3.6.1您可通过明九章软件服务平台向明九章提出充值费用的退费申请,经明九章教育工作人员人工审核后,将您账户中剩余未使用部分的金额退还于您,但根据您的使用服务情况,您在充值过程中因充值行为而获得的赠送金额将不被退还。
        </p>
        <p>
          3.6.2如您使用优惠券进行充值消费,则使用优惠券充值而获得的账户余额将不予以退还。
        </p>
        <p class="title">
          4.隐私的保护
        </p>
        <p >
          4.1本协议所指的“隐私”包括《电信和互联网用户个人信自保护规定》规定的用户个人信息的内容。
        </p>
        <p>
          4.2保护用户隐私和个人数据是明九章的一项基本制度,明九章将采取各种制度、安全技术和程序等措施来保护用户隐私和个人数据不被未经授权的访问、使用或泄漏,并保证不会将单个用户的注册资料及用户在使用明九章服务时存储在明九章的非公开内容向除合作单位以外的第三方公开或用于任何非法的用途,但下列情况除外:
        </p>
        <p>
          4.2.1事先获得用户的明确授权;
        </p>
        <p>
          4.2.2根据有关法律法规的要求;
        </p>
        <p>
          4.2.3按照相关政府主管部门的要求;
        </p>
        <p>
           4.2.4为维护社会公众的利益;
        </p>
        <p>
          4.2.5用户侵害本协议明九章的合法权益的情况。
        </p>
        <p>
          4.3用户在注册明九章账号或使用明九章软件服务平台的服务的过程中,需要提供一些必要的信息,例如:为向用户提供帐号注册服务或进行用户身份识别,需要用户填写手机号码;部分功能需要用户授权使用用户的相机或地理位置等。若用户不授权或提供的信息不完整,则无法使用本服务或在使用过程中受到限制。用户授权提供的信息,明九章承诺将采取措施保护用户的个人信息安全。
        </p>
        <p>
          4.4为了向您提供更好的用户体验和提高明九章软件服务平台服务质量,明九章将可能会收集使用或向第三方提供用户的非个人隐私信息。明九章将对该第三方使用用户个人数据的行为进行监督和管理,尽一切可能努力保护用户个人信息的安全
        </p>
        <p class="title">
          5.协议的变更
        </p>
        <p>
          5.1明九章对用户协议的修改将在明九章软件服务平台上予以公示,上述修改一经公布即视为明九章已通知用户。
        </p>
        <p>
          5.2如用户不同意条款的修改,可主动向明九章提出终止用户服务,但明九章不退还自开通充值用户服务之日起至提出终止用户服务所在月已享受用户服务的部分费用。如果用户继续享用充值用户服务的,则视为用户已经接受条款的修改。
        </p>
        <p class="title">
          6.服务的中断和终止
        </p>
        <p>
          6.1因明九章服务平台软件及网络的故障、数据库故障、软件升级等问题,或其他不可抗拒的事由,包括但不限于政府行为、自然灾害、黑客攻击等造成的服务中断或终止而造成的任何损失,明九章无需对您或任何第三方承担任何责任。但明九章应尽可能事先进行通告,并尽早恢复服务,尽全力采取合理措施将用户损失降到最低程度,用户应容忍不可抗力发生期间内对用户造成的损失。如在合理期限内,明九章仍然无法排除故障或恢复平台服务的,用户应以书面方式向明九章提出,并由双方协商后续补偿措施。
        </p>
        <p>
          6.2如用户违反或被视为违反本协议服务条款中的内容,明九章有权在不通知用户的情况下立即终止用户购买的所有服务,以及取消您的用户账户和使用权限,并不退还已充值的费用。
        </p>
        <p>
          6.3明九章未行使或延迟行使其在本协议项下的权利,并不构成对这些权利的放弃,而单一或部分行使其在本协议项下的任何权利并不排斥其任何其他权利的行使。明九章随时有权要求用户继续履行义务或承担相应的违约责任。
        </p>
        <p>
          7.其他
        </p>
        <p>
          7.1本协议未涉及的问题参照国家有关法律的法规,当本协议与国家相关法律法规冲突时,以国家法律法规为准。
        </p>
        <p>
          7.2本服务条款的生效、履行、解释及争议的解决均适用中华人民共和国法律,本服务条款因与中华人民共和国现行法律相抵触而导致部分条款无效的,不影响其他条款的效力。
        </p>
        <p>
          7.3如就本协议内容或其执行发生任何争议,应尽量友好协商解决;协商不成,则争议各方均可向江山市明九章文化传播工作室所在地有管辖权的人民法院提起诉讼。
        </p>
    </div>
</template>

<script>

export default {
  props: ['title'],
  components: {
    
  },
  data () {
      return {
        active: 0,
      }
  },
  mounted() {
    // console.log(this.props);
  },
  methods: {
    
  }

}
</script>

<style lang="less" scoped>
    .agreeMent {
      padding: 0.3rem;
      box-sizing: border-box;
      font-size: 0.26rem;
      p {
        line-height: 0.5rem;
      }
      .bold {
        color: #333;
        font-weight: bold;
        font-size: 0.28rem;
      }
      .title {
        margin: 0.15rem auto;
      }

    }
</style>
