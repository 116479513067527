<template>
    <div class="course">
      <Empty title="暂无课程系列" v-if="list.length == 0" />
      <div class="cn">
        <router-link
          :to="`/courseBuy/${it.id}`"
          tag="div" 
          class="item" 
          v-for="it in list" 
          :key="it.id"
        >
          <img v-if="it.isPayment == 0" class="icon" src="../../image/course/free.png" />
          <img v-if="it.isPayment == 1 && it.purchasedCourse == 0" class="icon" src="../../image/course/charge.png" />
          <img v-if="it.isPayment == 1 && it.purchasedCourse == 1" class="icon" src="../../image/course/charge1.png" />
          <span class="title">{{it.courseName}}</span>
          <i class="arrow" />
        </router-link>
      </div>
      <router-link to="/" tag="div" class="back"/>
    </div>
</template>

<script>
import Empty from '@/components/Empty';
export default {
  components: {
    Empty
  },
  data () {
      return {
        id: 0,
        active: 0,
        list: []
      }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getCourseList();
  },
  methods: {
    getCourseList() {
      this.$http.get(`/api/course/list/${this.id}`, {
      })
      .then((res) => {
        this.list = res.data.data;
      }).catch((err) => {
        this.validErrorInfo = err.errorMsg;
      })
    }
  }

}
</script>

<style lang="less" scoped>
    .course {
      .cn {
        min-height: 100vh;
        overflow-y: scroll;
        .item {
          height: 1rem;
          display: flex;
          align-items: center;
          padding: 0 0.3rem;
          box-sizing: border-box;
          position: relative;
          border-bottom: 1px solid #d9d9d9;
          .icon {
            width: 0.8rem;
            height: 0.8rem;
          }
          .title {
            font-size: 0.32rem;
            margin-left: 0.1rem;
            display: inline-block;
            width: 5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .arrow {
            position: absolute;
            right: 0.3rem;
            top: 50%;
            transform: translateY(-50%);
            background-image: url('../../image/task/right1.png');
            background-size: 100% 100%;
            width: 0.3rem;
            height: 0.3rem;
            display: block;
          }
        }
      }
    }
    .back {
      position: fixed;
      right: 0.3rem;
      bottom: 2rem;
      background-image: url('../../image/menu/back1.png');
      background-size: 100% 100%;
      width: 0.9rem;
      height: 0.9rem;
      display: block;
    }
</style>
