<template>
    <div>
        <div class="nbaMask"></div>
        <div class="nbaDialog">
            <div class="nbaDialogHd">
                <strong class="nbaDialogTitle"></strong>
            </div>
            <div class="nbaDialogBd" id="dialog_msg2">
                哈哈哈哈哈
            </div>
            <div class="nbaDialogFt">"
                <a href="javascript:;" class="nbaDialogBtn nbaDialogBtnPrimary" id="dialog_ok2">
                    知道了
                </a>
            </div>
        </div>
    </div>  
</template>
<script>
export default {
    data() {
        return {
            msg:'alert'
        }
    }
}
</script>

<style scoped lang="less">
    .nbaMask { position: fixed; z-index: 1000; top: 0; right: 0; left: 0; bottom: 0; background: rgba(0, 0, 0, 0.5); }                                                                                                                                                                       
    .nbaMaskTransparent { position: fixed; z-index: 1000; top: 0; right: 0; left: 0; bottom: 0; }                                                                                                                                                                                            
    .nbaDialog { position: fixed; z-index: 5000; width: 80%; max-width: 300px; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); background-color: #fff; text-align: center; border-radius: 8px; overflow: hidden; opacity: 1; color: white; }
    .nbaDialog .nbaDialogHd { padding: 0; }                                                                                                                                                                                                                        
    .nbaDialog .nbaDialogHd .nbaDialogTitle { font-size: 17px; font-weight: 400; }                                                                                                                                                                                                          
    .nbaDialog .nbaDialogBd { padding: 0.4rem; font-size: 0.3rem; line-height: 1.3; word-wrap: break-word; word-break: break-all; color: #333333; }                                                                                                                                        
    .nbaDialog .nbaDialogFt { position: relative; line-height: 1rem; font-size: 0.36rem; display: -webkit-box; display: -webkit-flex; display: flex; }                                                                                                                                         
    .nbaDialog .nbaDialogFt:after { content: ''; position: absolute; left: 0; top: 0; right: 0; height: 1px; border-top: 1px solid #e6e6e6; color: #e6e6e6; -webkit-transform-origin: 0 0; transform-origin: 0 0; -webkit-transform: scaleY(0.5); transform: scaleY(0.5); }
    .nbaDialog .nbaDialogBtn { display: block; -webkit-box-flex: 1; -webkit-flex: 1; flex: 1; color: #AB3B3A; text-decoration: none; -webkit-tap-highlight-color: transparent; position: relative; margin-bottom: 0; }                                                                       
    .nbaDialog .nbaDialogBtn:after { content: ''; position: absolute; left: 0; top: 0; width: 1px; bottom: 0; border-left: 1px solid #e6e6e6; color: #e6e6e6; -webkit-transform-origin: 0 0; transform-origin: 0 0; -webkit-transform: scaleX(0.5); transform: scaleX(0.5); }
    .nbaDialog a { text-decoration: none; -webkit-tap-highlight-color: transparent; }
</style>