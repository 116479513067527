<template>
    <div class="answer">
      <Empty title="暂无消息" v-if="records.length == 0" />
      <div class="loadMore" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
        <div class="message" v-for="item in records" :key="item.index">
          <div class="content">{{item.content}}</div>
          <div class="time">{{item.gmtCreate}}</div>
        </div>
      </div>

      
    </div>
</template>

<script>
import { formatDate, baseUrl } from '../../common/util';
import Empty from '@/components/Empty';

export default {
  components: {
    Empty
  },
  data () {
      return {
        records: [],
        busy: false,
        current: 1,
        size: 10,
      }
  },
  mounted() {
    
  },
  methods: {
    loadMore() {
      this.$http.post('/api/userMassage/pageList', {
        size: this.size,
        current: this.current,
      })
      .then((res) => {
        let data = res.data.data;
        if (this.records.length > 0 && data.records.length === 0) {
          this.busy = true;
          return;
        };
        this.records = this.records.concat(data.records);
        this.current ++;
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },

  }
}
</script>

<style lang="less" scoped>
  .answer {
    min-height: 100vh;
    background-color: #f4f6f6;
    .loadMore {
      height: 100vh;
      padding: 0.3rem;
      box-sizing: border-box;
      overflow: scroll;
      .message {
        margin-bottom: 0.3rem;
        .content {
          color: #040A23;
          font-size: 0.3rem;
          background-color: #fff;
          border-radius: 4px;
          width: 100%;
          display: block;
          padding: 0.2rem;
          box-sizing: border-box;
        }
        .time {
          line-height: 1.5;
          text-align: center;
          color: #aaaaaa;
          font-size: 0.28rem;
          margin: 0.15rem auto 0.24rem;
        }
      }
    }

  }
</style>
