<template>
    <div class="tipOut">
        <div class="tips" v-show="show">
            <div class="cn">{{tip}}</div>
        </div>   
    </div>
</template>
<script>
export default {
    props: ['tip'],
    data() {
        return {
           show: false
        }
    },
    mounted() {
        if (this.tip != '') {
            this.show = true;
            setTimeout(() => {
                this.show = false;
            }, 2000)
        }
    },
    methods: {
        emptyTip (val) {
            this.$emit('update:tip', '')
        }
    },
    watch: {
        tip(val, s) {
            if (val != '') {
                this.show = true;
                setTimeout(() => {
                    this.show = false;
                    this.emptyTip();
                }, 2000)
            }
        }
    }
}
</script>

<style scoped lang="less">
    .tipOut {
        width: 6rem;
        position: fixed;
        top: 50vh;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }
    .tips {
        opacity: .9;
        border-radius: .06rem;
        min-height: .72rem;
        background-color: #3a3a3a;
        display: inline-block;
        min-width: 1.8rem;
        max-width: 5.4rem;
        .cn {
            font-size: .28rem;
            color: #fff;
            word-break: break-all;
            text-align: center;
            max-width: 4rem;
            padding: .2rem .3rem;
            line-height: 1.6;
        }
    }
</style>

